<template>
    <page class="page__dashboard layout__list --show-content">
        <content>
            <jumbo class="--rounded">
                <classieicon><icon class="mdi-view-carousel --size2"></icon></classieicon>
                <div>
                    <h4>{{ $t('dashboard.jumbo_welcome_title') }}</h4>
                    <div class="subtitle">
                        <div>{{ $t('dashboard.jumbo_welcome_text') }}</div>
                    </div>
                </div>
            </jumbo>

            <br /><br />

            <cards>
                <card>
                    <h5>{{ $t('dashboard.latest_exports') }} <icon class="mdi-swap-vertical --color-highlight"></icon></h5>
                    <strong>{{ $t('dashboard.data_basic') }}</strong><br />
                    {{ data && data.export_dates && data.export_dates.basic || '-' }}<br />
                    <strong>{{ $t('dashboard.data_lessons') }}</strong><br />
                    {{ data && data.export_dates && data.export_dates.lessons || '-'}}<br />
                    <strong>{{ $t('dashboard.data_substitutions') }}</strong><br />
                    {{ data && data.export_dates && data.export_dates.substitutions  || '-'}}
                </card>

                <card>
                    <h5>{{ $t('dashboard.account_status') }} <icon class="mdi-information-outline --color-highlight"></icon></h5>

                    <strong>{{ $t('dashboard.account') }}</strong><br />
                    <span v-if="data && data.account && data.account.status">{{ $t(`dashboard.${data.account.status}`) }}</span><br />

                    <strong>{{ $t('dashboard.account_expires') }}</strong><br />
                    {{ data && data.account && data.account.expires_on }}
                </card>

                <card>
                    <h5>{{ $t('dashboard.user_stats') }} <icon class="mdi-chart-bar --color-highlight"></icon></h5>
                    <div>{{ $t('dashboard.user_stats_msg') }}</div><br />

                    <div>
                        <span class="--size4">{{ data && data.sessions && data.sessions.users || '0'}}</span>
                        <strong> {{ $tc('schedules.classes', 2) }}</strong><br />
                    </div>
                    <div>
                        <span class="--size4">{{ data && data.sessions && data.sessions.teachers || '0'}}</span>
                        <strong> {{ $tc('schedules.teachers', 2) }}</strong><br />
                    </div>
                </card>

                <div class="flex__full"></div>

                <card v-if="!data.monitor">
                    <div class="card__image" style="background-image:url('/img/dash_monitor.png');"></div>
                    <h5>{{ $t('dashboard.monitor_title') }}</h5>
                    <div>{{ $t('dashboard.monitor_text') }}</div><br />
                    <a href="https://classie.eu/#monitor" class="button --no-icon --small">{{ $t('actions.more_information') }}</a>
                </card>

                <card v-if="!data.day_scheduling">
                    <div class="card__image" style="background-image:url('/img/dash_drb.png');"></div>
                    <h5>{{ $t('dashboard.drb_title') }}</h5>
                    <div>{{ $t('dashboard.drb_text') }}</div>
                    <br />
                    <a href="https://classie.eu/#dagroosterbeheer" class="button --no-icon --small">{{ $t('actions.more_information') }}</a>
                </card>
            </cards>

        </content>
    </page>
</template>

<script>
export default {
    name: 'Dashboard',
    computed: {
        data() {
            return store.state.school || {};
        },
    },
};
</script>
