import Vue from 'vue';
import i18n from '@/i18n';
import EventBus from '@/classes/eventbus';
import * as Sentry from '@sentry/vue';

export default {
    namespaced: true,
    state: {
        msgServer: null,
    },
    getters: {},
    mutations: {
        set_messageserver(state, data) {
            state.msgServer = data;
        },
    },
    actions: {
        init({ rootState, commit, dispatch }) {
            if (rootState.settings.schoolid) {
                Vue.SSE(`${rootState.api.url}schools/${rootState.settings.schoolid}/stream`, { format: 'json' })
                    .then((sse) => {
                        commit('set_messageserver', sse);
                        sse.onError(() => {
                            // console.error('lost connection; giving up!', e);
                        });

                        sse.subscribe('save_action', (data) => {
                            dispatch('save_change', data);
                        });

                        sse.subscribe('system_action', (data) => {
                            dispatch('system_action', data);
                        });
                    });
            }
        },

        system_action({ commit }, data) {
            try {
                switch (data.type) {
                case 'exam_calculations':
                    commit('Exams/optimizingPeriod', (data.details && data.details.type === 'planning_done') ? false : data.details.examperiod, { root: true });
                    break;
                default:
                }
            } catch (err) {
                Sentry.captureException(err, {
                    tags: {
                        section: 'SSE/system_action',
                    },
                });
            }
        },

        save_change({ rootGetters, dispatch }, data) {
            if (!data || !data.id || data.user_id === rootGetters['Auth/user'].id) { return false; }
            // Not triggered by this user
            try {
                switch (data.type) {
                case 'classes':
                case 'students':
                case 'teachers':
                case 'rooms':
                case 'subjects':
                    dispatch('update_store_entity', data);
                    break;

                case 'monitors':
                case 'cards':
                    // OPTIMIZE: move to store
                    dispatch('update_content', data);
                    break;

                case 'notices':
                case 'schedules':
                    if (['drb', 'noticeEdit'].includes(router.currentRoute.name)) {
                        dispatch('update_drb', data);
                    } else if (data.type === 'schedules') {
                        dispatch('update_schedule', data);
                    }
                    break;

                case 'users':
                case 'changes':
                case 'settings':
                    dispatch('update_settings', data);
                    break;

                default:
                    return false;
                }
            } catch (err) {
                Sentry.captureException(err, {
                    tags: {
                        section: 'SSE/save_change',
                    },
                });
            }
            return true;
        },

        update_store_entity({ dispatch }, data) {
            const route = router.currentRoute;
            if (route.name && route.name.startsWith('schedules') && route.params && route.params.type === data.type && +route.params.id === data.id) {
                dispatch('toast_itemchanged', data);
                EventBus.$emit('open item changed', data);
            }
            dispatch('Items/get', { type: data.type }, { root: true });
        },

        update_content({ dispatch }, data) {
            const route = router.currentRoute;
            if (route.name && route.name.startsWith('content')) {
                if (route.params && route.params.type === data.type && +route.params.id === data.id) {
                    dispatch('toast_itemchanged', data);
                    EventBus.$emit('open item changed', data);
                } else {
                    EventBus.$emit('data changed', data);
                }
            }
        },

        update_drb({ dispatch }, data) {
            const route = router.currentRoute;
            let date = false;
            if (route.params && route.params.date) {
                date = +route.params.date;
            }

            if (
                date && data.details && data.details.date && data.details.date.from && data.details.date.to
                && date >= data.details.date.from && date <= data.details.date.to
            ) {
                if (route.name === 'noticeEdit') {
                    if (route.params && route.params.editid && +route.params.editid === data.id) {
                        dispatch('toast_itemchanged', data);
                        EventBus.$emit('open item changed', data);
                    }
                }
                this.dispatch('DRB/getNotices');
                this.dispatch('DRB/getConflicts');
            }
        },

        update_schedule() {
            // OPTIMIZE update timetables?
        },

        update_settings({ dispatch }) {
            dispatch('getSchooldata', undefined, { root: true });
        },

        toast_itemchanged(context, data) {
            Vue.toast({ msg: i18n.t('toasts.eventbus_datachanged', { user: data.user.split(' ')[0] }), classes: 'bg-warning' });
        },

        close({ commit, state }) {
            if (state.msgServer) {
                state.msgServer.close();
                commit('set_messageserver', null);
            }
        },
    },
};
