import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import VDragged from 'v-dragged';
import VueSSE from 'vue-sse';
import VueHtmlToPaper from 'vue-html-to-paper';
import apiErrors from '@/plugins/apiErrorhandling';
import Toaster from '@/plugins/toaster';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import './registerServiceWorker';
import i18n from './i18n';
import dates from './mixins/dates';

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['blob', 'cards', 'card', 'classieicon', 'controls', 'error', 'firstload', 'icon', 'jumbo', 'lesson', 'loading', 'main', 'modal', 'item', 'results', 'search', 'tag', 'tooltip', 'toasts', 'toast', 'list', 'page', 'wrapper'];
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VDragged);
Vue.use(VueSSE);
Vue.use(Toaster);
Vue.use(VueHtmlToPaper, {
    styles: ['/css/print_exams.css'],
});
Vue.mixin(apiErrors);
Vue.mixin(dates);

Sentry.init({
    Vue,
    dsn: 'https://6eec0ddeacda4d3cb9704828a4eff8e8@o507582.ingest.sentry.io/4505028893278208',
    ignoreErrors: [
        'ChunkLoadError',
        'Promise.allSettled is not a function',
        'e.$once is not a function',
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // custom
        'Can\'t find variable: ResizeObserver',
        'Importing a module script failed',
        'SecurityError: The operation is insecure',
        'Failed to fetch dynamically imported module',
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        'TypeError: cancelled',
        'TypeError: avbruten',
        'TypeError: geannuleerd',
        'TypeError: cancelado',
        'TypeError: annulé',
        'TypeError: annulleret',
        'TypeError: avbrutt',
        'TypeError: Abgebrochen',
        'TypeError: Load failed',
        'flatMap is not a function',
        'Unable to preload CSS for',
        'Can\'t find variable: __AutoFillPopupClose__',
        // Non-error promise rejections
        'Object Not Found Matching Id',
        'currentTarget, detail, isTrusted, target',
        'Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type',
        'Non-Error promise rejection captured with keys: Call timed out',
        'Non-Error promise rejection captured with keys: [object has no keys]',
    ],
    denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        // Custom
        /widget\.trengo\.eu/i,
        /googletagmanager\.com\/gtm\.js/i,
    ],
    allowUrls: [
        'dashboard.classie.eu',
        'dashboard.classie.app',
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: APPLICATION_VERSION,
});

global.router = router;
global.store = store;

// OPTIMIZE online/offline state
// OPTIMIZE modal in seperate component
// OPTIMIZE abort api calls in modal on close
// OPTIMIZE Ctrl+S Keyboard shortcut
// OPTIMIZE Ctrl+F Resultslist Jump to find
// OPTIMIZE Ctrl+A Resultslist select all
// OPTIMIZE Ctrl+N Create new
new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
    mounted() {
        this.$store.commit('update_time');
        setInterval(() => { this.$store.commit('update_time'); }, 1000 * 60 * 5);
    },
    beforeDestroy() {
        store.dispatch('SSE/close');
    },
}).$mount('#app');
