<template>
    <error overlay="true">
        <classieicon><icon class="--size2" :class="[icon, { 'rotate': icon === 'mdi-loading' }]"></icon></classieicon>
        <h2>{{ $t(title) }}</h2>
        <h4 class="subtitle" v-html="$t(msg)"></h4>
    </error>
</template>

<script>
export default {
    name: 'Empty',
    props: {
        type: String,
    },
    data() {
        return {
            icon: 'mdi-border-none-variant',
            title: 'states.empty',
            msg: 'states.empty_msg',
        };
    },
    mounted() {
        if (['cards', 'monitors'].includes(this.type) && !store.state.school.monitor) {
            this.icon = 'mdi-television';
            this.title = 'states.no-monitor';
            this.msg = 'states.no-monitor_msg';
        } else if (this.type === 'exams-optimizing') {
            this.icon = 'mdi-loading';
            this.title = 'exams.states.optimizing_title';
            this.msg = 'exams.states.optimizing_msg';
        } else if (this.type === 'exams-noperiod') {
            this.title = 'exams.period_choose_first';
        }
    },
};
</script>
