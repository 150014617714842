import Vue from 'vue';

const apiErrors = {
    methods: {
        apiError(err, allowedCodes) {
            let errordata = err.data || {};
            let statuscode = err.status || 0;
            if (statuscode === 'error' && err.message === 'Timeout') {
                statuscode = 99992;
            }
            if (err.data && typeof err.data === 'string') {
                errordata = JSON.parse(errordata);
            }
            if (errordata.error_code) {
                statuscode = errordata.error_code;
            }
            console.group(`Api Error: ${errordata.message || ''}`);
            if (allowedCodes && allowedCodes.includes(statuscode)) {
                console.log('Statuscode is allowed');
            } else {
                this.processError(statuscode, errordata);
            }
            console.log(err);
            console.trace();
            console.groupEnd();
        },

        processError(code, data = {}) {
            const errorObj = store.getters['Errors/errorByCode'](code);
            if (errorObj) {
                const msg = errorObj.useResponseMsg ? (data.message || 'Error') : (errorObj.message || 'Error');
                if (errorObj.fatal === false) {
                    Vue.toast({ msg, classes: 'bg-danger' });
                } else if (errorObj.fatal === true) {
                    store.state.app.loading_fullscreen.show = true;
                    store.state.app.loading_fullscreen.title = errorObj.title || 'Error';
                    store.state.app.loading_fullscreen.message = msg;
                }

                if (typeof errorObj.callback === 'function') {
                    errorObj.callback();
                }
            } else {
                console.warn('ErrorObj not found', code, data);
                if (window.bugsnagClient) {
                    window.bugsnagClient.notify(new Error('ErrorObj not found'), {
                        metaData: { code, data },
                    });
                }
            }
        },
    },
};

export default apiErrors;
