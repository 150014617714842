import Vue from 'vue';
import { api } from '@/plugins/api';
import apiErrors from '@/plugins/apiErrorhandling';
import { handle_empty_characters, remove_keys_from_object } from '@/assets/js/helpers';
import i18n from '@/i18n';

export default {
    namespaced: true,
    state: {
        classes: {
            items: [],
            group: {
                value: 'year',
                title: `${i18n.t('fields.year')} %name%`,
            },
            search: {
                keys: ['name'],
                placeholder: i18n.t('search.name'),
            },
            dont_save: ['stats'],
        },
        rooms: {
            filter_active: false,
            items: [],
            items_filtered: [],
            search: {
                keys: ['name', 'shorthand', 'type'],
                placeholder: i18n.t('search.name_and_type'),
            },
        },
        students: {
            items: [],
            search: {
                keys: ['name', 'shorthand'],
                placeholder: i18n.t('search.name_and_shorthand'),
            },
        },
        subjects: {
            items: [],
            search: {
                keys: ['name', 'shorthand'],
                placeholder: i18n.t('search.name_and_shorthand'),
            },
            multi_select: false,
        },
        teachers: {
            filter_active: false,
            items: [],
            items_filtered: [],
            search: {
                keys: ['name', 'shorthand', 'first_name'],
                placeholder: i18n.t('search.name_and_shorthand'),
            },
            add: {
                name: '',
                shorthand: '',
                first_name: '',
                salutation: '',
                pincode: '',
                visible: 1,
                status_info: null,
            },
            dont_save: ['classes', 'stats', 'status_info', 'subjects'],
        },
    },
    getters: {
        object: (state) => (type) => state[type],
        departmentobject: (state, getters, rootState) => (type) => {
            let obj = getters.object(type);
            if (!rootState.app.department) {
                return obj;
            }
            const items = getters.departmentitems(type);
            obj = JSON.parse(JSON.stringify(obj));
            obj.items = items;
            return obj;
        },
        items: (state) => (type) => state[type].items,
        departmentitems: (state, getters, rootState) => (type) => {
            const items = getters.items(type);
            if (rootState.app.department) {
                return items.filter(
                    (it) => !it.departments
                        || it.departments.length === 0
                        || it.departments.includes(rootState.app.department),
                );
            }
            return items;
        },
        item: (state) => (type, ids) => {
            if (!ids || typeof ids === 'undefined') {
                return false;
            }
            if (typeof ids === 'string' || typeof ids === 'number') {
                return state[type].items.find((it) => ids === it.id) || {};
            }
            return state[type].items.filter((it) => ids.includes(it.id)) || [];
        },

        cachedClassesNames: (state) => state.classes.items.reduce((obj, item) => {
            obj[item.id] = item.name || item.shorthand;
            return obj;
        }, {}),

        cachedSubjectsNames: (state) => state.subjects.items.reduce((obj, item) => {
            obj[item.id] = item.name || item.shorthand;
            return obj;
        }, {}),

        cachedRoomsNames: (state) => state.rooms.items.reduce((obj, item) => {
            obj[item.id] = item.name || item.shorthand;
            return obj;
        }, {}),

        cachedTeachersNames: (state) => state.teachers.items.reduce((obj, item) => {
            obj[item.id] = item.name || item.shorthand;
            return obj;
        }, {}),

        roomtypes: (state) => {
            const types = state.rooms.items.map((it) => it.type || undefined);
            const set = [...new Set(types)];
            const items = [];
            set.forEach((item, index) => {
                if (item) {
                    items.push({ id: index + 1, name: item });
                }
            });
            return items;
        },
    },
    mutations: {
        storeItems(state, { type, items }) {
            state[type].items = items;
        },

        storeFilteredItems(state, { type, items, active }) {
            state[type].items_filtered = items;
            state[type].filter_active = active;
        },

        addItem(state, { type, item }) {
            state[type].items.push(item);
        },

        updateItem(state, { type, item }) {
            const obj = state[type].items.find((it) => it.id === item.id);
            if (obj) {
                Object.assign(obj, { ...item });
            }
        },

        removeItems(state, { type, ids }) {
            ids.forEach((id) => {
                const index = state[type].items.findIndex((x) => x.id === id);
                state[type].items.splice(index, 1);
            });
        },
    },
    actions: {
        get({ commit, rootState }, { type, filters = {} }) {
            return new Promise((resolve, reject) => {
                api.get({
                    name: type,
                    endpoint: `schools/${rootState.settings.schoolid}/${type}/`,
                    data: filters,
                    loading: true,
                })
                    .then((r) => {
                        if (r.status === 200 && r.data && r.data.status === 'success') {
                            if (
                                type === 'rooms'
                                && !r.data.results.length
                                && Object.keys(r.data.results).length
                            ) {
                                const groups = Object.keys(r.data.results);
                                for (let i = 0, len = groups.length; i < len; i += 1) {
                                    r.data.results[groups[i]] = handle_empty_characters(
                                        r.data.results[groups[i]],
                                        ['name', 'shorthand'],
                                    );
                                }
                            } else if (type === 'classes') {
                                r.data.results = handle_empty_characters(r.data.results, ['name']);
                            } else if (type === 'rooms') {
                                r.data.results = handle_empty_characters(r.data.results, [
                                    'name',
                                    'shorthand',
                                ]);
                            }

                            const data = {
                                type,
                                items: r.data.results,
                            };

                            if (!Object.keys(filters).length) {
                                commit('storeItems', data);
                            }
                            resolve(data);
                        } else {
                            throw new Error('Unexpected result', r);
                        }
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        getMultiple({ dispatch }, types) {
            types.forEach((type) => {
                dispatch('get', { type });
            });
        },

        patch({ commit, state, rootState }, { type, item }) {
            return new Promise((resolve, reject) => {
                let savedata = { ...item };
                savedata = remove_keys_from_object(savedata, state[type].dont_save);

                api.patch({
                    name: 'patch_item',
                    endpoint: `schools/${rootState.settings.schoolid}/${type}/${item.id}`,
                    data: savedata,
                    loading: true,
                })
                    .then((r) => {
                        Vue.toast({ msg: i18n.t('toasts.save_success') });
                        commit('updateItem', { type, item });

                        resolve(r);
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        post({
            commit, dispatch, state, rootState,
        }, { type, item }) {
            return new Promise((resolve, reject) => {
                let savedata = { ...item };
                savedata = remove_keys_from_object(savedata, state[type].dont_save);

                api.post({
                    name: 'post_item',
                    endpoint: `schools/${rootState.settings.schoolid}/${type}/`,
                    data: savedata,
                    loading: true,
                })
                    .then((r) => {
                        Vue.toast({ msg: i18n.t('toasts.save_success') });
                        if (!r.data || !r.data.data) {
                            dispatch('Items/get', type);
                        } else {
                            commit('addItem', { type, item: r.data.data });
                        }
                        resolve(r);
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        delete({ commit, rootState }, { type, ids }) {
            return new Promise((resolve, reject) => {
                api.delete({
                    name: 'delete_items',
                    endpoint: `schools/${rootState.settings.schoolid}/${type}/${ids.join(',')}`,
                    loading: true,
                })
                    .then(() => {
                        commit('removeItems', { type, ids });
                        Vue.toast({ msg: i18n.t('toasts.delete_success') });
                        resolve();
                    })
                    .catch((err) => {
                        Vue.toast({ msg: i18n.t('toasts.delete_error'), classes: 'bg-danger' });
                        console.log(err);
                        reject();
                    });
            });
        },
    },
};
