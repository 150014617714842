import Vue from 'vue';
import i18n from '@/i18n';
import dates from '@/mixins/dates';

/**
 * build_html_attr
 * turn all items in an object into key="val"
 */
export function build_html_attr(attr) {
    return Object.entries(attr).map(([key, val]) => `${key}="${val}"`).join(' ');
}

/**
 * build_nested_check
 * create vue-check for nested object path:
 * item.client.name -> item.client && item.client.name
 * data_elem is an optional prefixing elem, for "item" for example
 * first param does not get checked.
 */
export function build_nested_check(path_object, data_elem) {
    if (data_elem) { path_object.unshift(data_elem); }
    let last_val = '';
    const path = path_object.reduce((acc, cur, ind) => {
        let val = acc;
        if (ind === 1) {
            val += `.${cur}`;
            last_val = val;
        } else if (ind > 1) {
            last_val = `${last_val}.${cur}`;
            val += ` && ${last_val}`;
        }
        return val;
    });
    return path;
}

export function find_hour(lesson, type) {
    let expr = (hour) => (hour.from === lesson.start && hour.to === lesson.end) || (lesson.hour && hour.hour === lesson.hour);

    if (type === 'recess_starthour') {
        expr = (hour) => hour.from === lesson.start;
        // expr = hour => hour.from === lesson.start && hour.to > lesson.end;
    } else if (type === 'start') {
        expr = (hour) => hour.from === lesson.start;
    } else if (type === 'end_at_start') {
        expr = (hour) => hour.to === lesson.start;
    } else if (type === 'starts_within') {
        expr = (hour) => hour.from < lesson.start && hour.to > lesson.start;
    }
    return this.hours.find(expr);
}

export function groupBy(xs, key) {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

/**
 * capitalize_first
 * Capitalizes first letter of string
 */
export function capitalize_first(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function count_array(array) {
    return array.reduce((a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }), {});
}

export function array_closest(array, val, prev) {
    const arrayLength = array.length;
    const lastValue = array[arrayLength - 1];
    for (let i = 0, len = arrayLength; i < len; i += 1) {
        if (prev) {
            if (val > lastValue) {
                return { index: arrayLength - 1, value: lastValue };
            }
            if (array[i] > val) {
                return { index: i - 1, value: array[i - 1] };
            }
        } else if (array[i] >= val) {
            return { index: i, value: array[i] };
        }
    }
    return { index: arrayLength - 1, value: array[arrayLength] };
}

/**
 * path_to_keyname
 * rewrite objectpath to single level key
 */
export function path_to_keyname(path) {
    const parts = path.split('.');
    return parts.reduce((acc, cur) => acc + capitalize_first(cur));
}

/**
 * flatten_property
 * copy item in subobject to root level
 */
export function flatten_property(obj, key, keyname) {
    // eslint-disable-next-line
    const objectPath = require('object-path');
    if (!keyname) { keyname = path_to_keyname(key); }
    for (let i = 0, len = obj.length; i < len; i += 1) {
        obj[i][keyname] = objectPath.get(obj[i], key);
    }
    return obj;
}
export function intersect(a, b) {
    return [...new Set(a)].filter((x) => new Set(b).has(x));
}

/**
 * get_property
 * get nested properties from object with dot notation
 */
export default function get_property(property_name, object) {
    const parts = property_name.split('.');
    return parts.reduce((prev, curr) => prev && prev[curr], object);
}

/**
 * string_to_numeric_array
 * split incoming string into array values, with parse int.
 */
export function string_to_numeric_array(input) {
    const ids = input ? input.split(',') : [];
    return ids.map((sel) => {
        if (['everything', 'none', 'add'].includes(sel)) { return sel; }
        return +sel;
    });
}

/**
 * remove_empty_characters
 * remove u000 characters from a single item, based on provided props
 */
export function remove_empty_characters(item, props) {
    if (!item) { return item; }
    props.forEach((prop) => {
        if (item[prop]) {
            // eslint-disable-next-line
            item[prop] = item[prop].replace(/\u0000/g, '');
        }
    });
    return item;
}

/**
 * handle_empty_characters
 * remove u000 characters from an array of items, based on provided props
 */
export function handle_empty_characters(data, props) {
    for (let i = 0, len = data.length; i < len; i += 1) {
        data[i] = remove_empty_characters(data[i], props);
    }
    return data;
}

/**
 * remove_keys_from_object
 */
export function remove_keys_from_object(data, keys) {
    if (!keys || typeof keys !== 'object') { return data; }

    for (let i = 0, len = keys.length; i < len; i += 1) {
        if (data[keys[i]]) { delete data[keys[i]]; }
    }
    return data;
}

export function array_to_linked_string(array, maxlength = 8, { or = '', more = '' } = {}) {
    if (array.length === 1) {
        return array[0];
    }
    if (array.length === 2) {
        return array.join(` ${or} `);
    }
    if (array.length > maxlength) {
        return `${array.slice(0, maxlength).join(', ')} ${more}`;
    }
    if (array.length > 2) {
        return `${array.slice(0, -1).join(', ')} ${or} ${array.slice(-1)}`;
    }
    return false;
}

export function array_unique(array, prop) {
    const unique = {};
    const distinct = [];
    for (let i = 0, len = array.length; i < len; i += 1) {
        if (typeof unique[array[i][prop]] === 'undefined') {
            distinct.push(array[i][prop]);
        }
        unique[array[i][prop]] = 0;
    }
    return distinct;
}

export function deletetimer() {
    return new Promise((resolve, reject) => {
        const timeout = 4000;
        Vue.toast({
            msg: i18n.t('toasts.delete_busy'),
            html: `<button type="button" class="button --small --no-icon" onclick="clearTimeout(window.delete_timer);">${i18n.t('actions.cancel')}</button>`,
        });
        window.delete_timer_checker = setTimeout(() => { reject(); }, timeout + 5);
        window.delete_timer = setTimeout(() => { clearTimeout(window.delete_timer_checker); resolve(); }, timeout);
    });
    // OPTIMIZE rewrite to store
}

export function process_lesson(data) {
    remove_empty_characters(data.class, ['shorthand', 'name']);
    remove_empty_characters(data.room, ['name']);

    const lesson = {
        start: null,
        activity: data.activity,
        class: {
            name: data.class.name || data.class.shorthand || '',
            shorthand: data.class.shorthand || '',
            changed: false,
        },
        teacher: {
            name: null,
            shorthand: null,
            name_old: null,
            shorthand_old: null,
            changed: false,
        },
        room: {
            name: null,
            name_old: null,
            changed: false,
        },
        subject: {
            name: null,
            shorthand: null,
            name_old: null,
            shorthand_old: null,
            changed: false,
        },
        reasons: [],
    };

    if (store.state.school.settings.show_hours && data.hour) {
        lesson.start = `${i18n.t('drb.hour')} ${data.hour}`;
    } else {
        lesson.start = dates.methods.time_to(data.start);
    }

    lesson.subject.name = data.subject.name || data.subject.shorthand || '';
    lesson.subject.shorthand = data.subject.shorthand || '';
    if (data.previous && data.previous.subject && data.previous.subject.id && (data.previous.subject.id !== data.subject.id)) {
        lesson.subject.changed = true;
        lesson.subject.name_old = data.previous.subject.name || data.previous.subject.shorthand || '';
        lesson.subject.shorthand_old = data.previous.subject.shorthand || '';
        lesson.reasons.push(i18n.t('lessons.changed_subject'));
    }

    lesson.room.name = data.room.name || '';
    if (data.previous && data.previous.room.id && (data.previous.room.id !== data.room.id)) {
        lesson.room.changed = true;
        lesson.room.name_old = data.previous.room.name || '';
        if (lesson.room.name) {
            lesson.reasons.push(`${i18n.t('lessons.changed_room')}: ${lesson.room.name}`);
        } else {
            lesson.reasons.push(i18n.t('lessons.changed_noroom'));
        }
    }

    lesson.teacher.name = data.teacher.name || data.teacher.shorthand || '';
    lesson.teacher.shorthand = data.teacher.shorthand || '';
    if (data.previous && data.previous.teacher && data.previous.teacher.id && (data.previous.teacher.id !== data.teacher.id)) {
        lesson.teacher.changed = true;
        lesson.teacher.name_old = data.previous.teacher.name || data.previous.teacher.shorthand || '';
        lesson.teacher.shorthand_old = data.previous.teacher.shorthand || '';
        if (lesson.teacher.name) {
            lesson.reasons.push(i18n.t('lessons.changed_teacher'));
        } else {
            lesson.reasons = [i18n.t('lessons.changed_noteacher')];
        }
    }

    if (data.activity) {
        lesson.reasons = [`${i18n.t('drb.notice_activity')}: ${data.remarks || ''}`];
    }
    return lesson;
}

export { get_property };
