import i18n from '@/i18n';

export default {
    namespaced: true,
    state: {
        errors: {
            4: {
                name: 'Item niet gevonden',
                fatal: false,
                title: i18n.t('toasts.not_found_item'),
                message: '',
            },
            11: {
                name: 'Geen Classie abonnement meer',
                fatal: true,
                title: i18n.t('errors.classie-not-found'),
                message: i18n.t('errors.classie-not-found_msg'),
            },
            16: 403,
            99: {
                name: 'Unspecified',
                message: i18n.t('errors.unspecified_msg'),
                fatal: true,
                callback: () => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                },
            },
            120: {
                name: 'Under construction',
                title: i18n.t('errors.construction'),
                message: i18n.t('errors.construction'),
                fatal: true,
                callback: () => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 120000);
                },
            },
            400: {
                name: 'Unsupported',
                fatal: false,
                title: i18n.t('errors.unsupported'),
                useResponseMsg: true,
            },
            403: {
                name: 'Invalid API key',
                fatal: true,
                useResponseMsg: true,
                callback: () => {
                    setTimeout(() => {
                        router.push('/logout/').catch(() => {});
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }, 1000);
                },
            },
            429: {
                name: 'Ratelimit reached',
                fatal: true,
                useResponseMsg: false,
                title: i18n.t('errors.ratelimit'),
                message: i18n.t('errors.ratelimit_msg'),
            },
            99992: {
                name: 'Timeout',
                fatal: false,
                title: i18n.t('errors.timeout'),
                message: i18n.t('errors.timeout_msg'),
            },
        },
    },
    getters: {
        errorByCode: (state) => (code) => {
            let obj = state.errors[code];
            if (typeof obj === 'number') {
                obj = state.errors[obj];
            }
            return obj;
        },
    },
};
