import Vue from 'vue';
import Router from 'vue-router';
import { string_to_numeric_array } from '@/assets/js/helpers';
import Dashboard from './views/Dashboard.vue';
import Empty from './views/Empty.vue';
import i18n from './i18n';
import dates from './mixins/dates';

function scrollBehavior(to, from, savedPosition) {
    if (typeof to.params.scrollTo !== 'undefined') {
        return to.params.scrollTo;
    }
    if (savedPosition) {
        return savedPosition;
    }
    if (to.hash) {
        return { selector: to.hash };
    }
    if (to.meta) {
        if (to.meta.scrollTop === false) { return false; }
        if (to.meta.scrollTop) {
            if (!Number.isNaN(to.meta.scrollTop)) {
                return { x: 0, y: to.meta.scrollTop };
            }
            return { selector: to.meta.scrollTop };
        }
    }
    return { x: 0, y: 0 };
}

function meta(to) {
    const { app } = store.state;
    let title = app.name;

    if (to.meta && to.meta.name) {
        let { name } = to.meta;
        if (typeof to.meta.name === 'function') {
            name = to.meta.name(to);
        }
        title = to.meta.name_only ? name : `${name}${app.name_separator}${title}`;
    }

    if (title.indexOf('%') > -1) {
        title = title.replace(/%(\w.+)%/g, (m, m1) => {
            const parts = m1.split('.');
            let val = to.params[parts[0]];
            if (val && parts[1] === 'translate') {
                const translatepart = parts.slice(2, 4).join('.').replace('prop', val);
                val = i18n.t(translatepart);
            }
            return val || '';
        });
    }

    document.title = title;
    if (!to.meta.update_meta === false && app.update_meta) {
        let desc = app.meta.description;
        let img = app.meta.image;
        const url = app.base_url + to.fullPath;

        if (to.meta) {
            if (to.meta.description) { desc = to.meta.description; }
            if (to.meta.image) { img = to.meta.image; }
        }

        const title_items = document.head.getElementsByClassName('meta__title');
        for (let i = 0, len = title_items.length; i < len; i += 1) {
            title_items[i].setAttribute('content', title);
        }

        const url_items = document.head.getElementsByClassName('meta__url');
        for (let i = 0, len = url_items.length; i < len; i += 1) {
            url_items[i].setAttribute('content', url);
        }
        document.head.getElementsByClassName('canonical__url')[0].setAttribute('href', url);

        const desc_items = document.head.getElementsByClassName('meta__desc');
        for (let i = 0, len = desc_items.length; i < len; i += 1) {
            desc_items[i].setAttribute('content', desc);
        }

        const img_items = document.head.getElementsByClassName('meta__image');
        for (let i = 0, len = img_items.length; i < len; i += 1) {
            img_items[i].setAttribute('content', img);
        }
    }
}

Vue.use(Router);

const propvalidator_exams = (route) => {
    const props = { ...route.params };
    props.periodid = +props.periodid;
    props.classid = string_to_numeric_array(props.classid);
    props.examid = string_to_numeric_array(props.examid);
    if (props.year) { props.year = +props.year; }
    return props;
};

const propvalidator_ids = (route) => {
    const props = { ...route.params };
    props.id = string_to_numeric_array(props.id);
    return props;
};

const propvalidator_drb = (route) => {
    const props = { ...route.params };
    if (!props.date || props.date === 'today') {
        props.date = dates.methods.timestamp_to('now', 'timestamp', true);
    } else if (props.date === 'tomorrow') {
        props.date = dates.methods.timestamp_to('tomorrow', 'timestamp', true);
    }
    if (props.editid) { props.editid = +props.editid; }
    props.date = +props.date;
    return props;
};

const create_router = () => new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: '--active',
    routes: [
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
        },
        {
            path: '/schedules',
            redirect: '/schedules/teachers',
        },
        {
            path: '/schedules/:type',
            component: () => import(/* webpackChunkName: "schedules" */ './views/Schedules.vue'),
            props(route) {
                return propvalidator_ids(route);
            },
            children: [
                {
                    path: '',
                    name: 'schedules_item_empty',
                    component: Empty,
                    meta: {
                        name: (to) => i18n.tc(`schedules.${to.params.type}`, 2),
                    },
                },
                {
                    path: ':id',
                    name: 'schedules_item',
                    component: () => import(/* webpackChunkName: "SchedulesItem" */ './views/schedules/Item.vue'),
                    props(route) {
                        return propvalidator_ids(route);
                    },
                    meta: {
                        name: (to) => i18n.tc(`schedules.${to.params.type}`, 1),
                    },
                },
            ],
        },
        {
            path: '/content',
            redirect: '/content/monitors',
        },
        {
            path: '/content/push_send',
            component: () => import(/* webpackChunkName: "content" */ './views/Content.vue'),
            props(route) {
                return propvalidator_ids(route);
            },
            children: [
                {
                    path: '',
                    name: 'content_push_item',
                    component: () => import(/* webpackChunkName: "contentPush" */ './views/content/Push.vue'),
                    meta: {
                        name: i18n.t('content.pushnotification_plural'),
                    },
                },
            ],
        },
        {
            path: '/content/:type',
            component: () => import(/* webpackChunkName: "content" */ './views/Content.vue'),
            props(route) {
                return propvalidator_ids(route);
            },
            children: [
                {
                    path: '',
                    name: 'content_item_empty',
                    component: Empty,
                    props: true,
                    meta: {
                        name: '%type.translate.content.prop_plural%',
                    },
                },
                {
                    path: ':id',
                    name: 'content_item',
                    component: () => import(/* webpackChunkName: "ContentItem" */ './views/content/Item.vue'),
                    props(route) {
                        return propvalidator_ids(route);
                    },
                    meta: {
                        name: '%type.translate.content.prop_plural%',
                    },
                },
            ],
        },
        {
            path: '/login',
            name: 'login',
            alias: '/logout',
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
            meta: {
                guest: true,
            },
        },
        {
            path: '/drb/changes',
            name: 'changes',
            component: () => import(/* webpackChunkName: "drb" */ './views/drb/Changes.vue'),
        },
        {
            path: '/drb/:date?',
            props(route) {
                return propvalidator_drb(route);
            },
            component: () => import(/* webpackChunkName: "drb" */ './views/DRB.vue'),
            children: [
                {
                    path: '',
                    name: 'drb',
                    components: {
                        default: () => import(/* webpackChunkName: "drb" */ './views/DRB.vue'),
                        notices: () => import(/* webpackChunkName: "drb" */ './views/drb/Notices.vue'),
                        conflicts: () => import(/* webpackChunkName: "drb" */ './views/drb/Conflicts.vue'),
                    },
                    meta: {
                        name: i18n.t('settings.changes_plural'),
                    },
                    children: [
                        {
                            path: 'notice/:edittype/:editid',
                            name: 'noticeEdit',
                            meta: {
                                name: i18n.t('drb.notices_single'),
                            },
                            components: {
                                noticeEdit: () => import(/* webpackChunkName: "drb" */ './views/modals/Notice_edit.vue'),
                            },
                            props: { noticeEdit: true },
                        },
                    ],
                },
            ],
        },
        {
            path: '/exams',
            name: 'exams',
            component: () => import(/* webpackChunkName: "exams" */ './views/Exams.vue'),
            meta: {
                name: i18n.t('exams.item_plural'),
            },
        },
        {
            path: '/exams/:periodid',
            name: 'exams_period',
            props(route) {
                return propvalidator_exams(route);
            },
            component: () => import(/* webpackChunkName: "exams" */ './views/Exams.vue'),
            meta: {
                name: i18n.t('exams.item_plural'),
            },
            children: [
                {
                    path: 'create', // exams/periodid/create/classid/examid/
                    component: () => import(/* webpackChunkName: "examsCreate" */ './views/exams/Create.vue'),
                    props(route) {
                        return propvalidator_exams(route);
                    },
                    children: [
                        {
                            path: ':classid?',
                            component: () => import(/* webpackChunkName: "examsCreate" */ './views/exams/CreateExams.vue'),
                            props(route) {
                                return propvalidator_exams(route);
                            },
                            children: [
                                {
                                    path: '',
                                    name: 'exams_create_exams_empty',
                                    props: true,
                                    component: Empty,
                                    meta: {
                                        name: i18n.t('exams.item_plural'),
                                    },
                                },
                                {
                                    path: ':examid',
                                    name: 'exams_create_exam',
                                    component: () => import(/* webpackChunkName: "examsCreate" */ './views/exams/CreateExamsItem.vue'),
                                    props(route) {
                                        return propvalidator_exams(route);
                                    },
                                    meta: {
                                        name: i18n.t('exams.item_plural'),
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'plan',
                    component: () => import(/* webpackChunkName: "examsPlan" */ './views/exams/Plan.vue'),
                    props(route) {
                        return propvalidator_exams(route);
                    },
                    children: [
                        {
                            path: '',
                            name: 'exams_plan_empty',
                            props: true,
                            component: Empty, // OPTIMIZE update
                            meta: {
                                name: i18n.t('exams.item_plural'),
                            },
                        },
                        {
                            path: ':year?/:examid?',
                            component: () => import(/* webpackChunkName: "examsCreate" */ './views/exams/PlanCalendar.vue'),
                            props(route) {
                                return propvalidator_exams(route);
                            },
                            meta: {
                                name: i18n.t('exams.item_plural'),
                            },
                        },
                    ],
                },
                {
                    path: 'overview',
                    component: () => import(/* webpackChunkName: "examsOverview" */ './views/exams/Overview.vue'),
                    props(route) {
                        return propvalidator_exams(route);
                    },
                    children: [
                        {
                            path: '',
                            alias: 'overview',
                            name: 'exams-overview',
                            component: () => import(/* webpackChunkName: "examsOverview" */ './views/exams/OverviewDashboard.vue'),
                            props(route) {
                                return propvalidator_exams(route);
                            },
                            meta: {
                                name: i18n.t('exams.mode_overview'),
                            },
                        },
                        {
                            path: 'exams',
                            name: 'exams-overview-exams',
                            component: () => import(/* webpackChunkName: "examsOverview" */ './views/exams/OverviewExams.vue'),
                            props(route) {
                                return propvalidator_exams(route);
                            },
                            meta: {
                                name: i18n.t('exams.item_plural'),
                            },
                        },
                        {
                            path: 'stats',
                            name: 'exams-overview-stats',
                            component: () => import(/* webpackChunkName: "examsOverview" */ './views/exams/OverviewStats.vue'),
                            props(route) {
                                return propvalidator_exams(route);
                            },
                            meta: {
                                name: i18n.t('exams.stats_plural'),
                            },
                        },
                        {
                            path: 'jokers',
                            name: 'exams-overview-jokers',
                            component: () => import(/* webpackChunkName: "examsOverview" */ './views/exams/OverviewJokers.vue'),
                            props(route) {
                                return propvalidator_exams(route);
                            },
                            meta: {
                                name: i18n.t('exams.jokers'),
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/settings/',
            component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue'),
            children: [
                {
                    path: 'school',
                    alias: '',
                    name: 'settings_school',
                    component: () => import(/* webpackChunkName: "settingsSchool" */ './views/settings/School.vue'),
                    meta: {
                        name: i18n.t('settings.settings_plural'),
                        requires_admin: true,
                    },
                },
                {
                    path: 'schedules',
                    name: 'settings_schedules',
                    component: () => import(/* webpackChunkName: "settingsSchedules" */ './views/settings/Schedules.vue'),
                    meta: {
                        name: i18n.t('settings.settings_plural'),
                        requires_admin: true,
                    },
                },
                {
                    path: 'changes',
                    name: 'settings_changes',
                    component: () => import(/* webpackChunkName: "settingsChanges" */ './views/settings/Changes.vue'),
                    meta: {
                        name: i18n.t('settings.settings_plural'),
                        requires_admin: true,
                    },
                },
                {
                    path: 'users',
                    component: () => import(/* webpackChunkName: "SettingsUsers" */ './views/settings/Users.vue'),
                    props: true,
                    children: [
                        {
                            path: '',
                            name: 'settings_user_empty',
                            component: Empty,
                            meta: {
                                name: i18n.t('settings.users_plural'),
                            },
                        },
                        {
                            path: ':id',
                            name: 'settings_user',
                            component: () => import(/* webpackChunkName: "SettingsUsersItem" */ './views/settings/UsersItem.vue'),
                            props: true,
                            meta: {
                                name: i18n.t('settings.users_plural'),
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/styleguide',
            name: 'styleguide',
            component: () => import(/* webpackChunkName: "styleguide" */ './views/Styleguide.vue'),
            meta: {
                name: 'Styleguide',
            },
        },
        {
            path: '/dev',
            name: 'dev',
            component: () => import(/* webpackChunkName: "dev" */ './views/Dev.vue'),
            meta: {
                name: 'dev',
            },
        },
    ],
    scrollBehavior,
});

const router = create_router();

const onEmptyLink = (next) => {
    if (store.getters['Auth/rights']('day_scheduling') && store.state.school.settings.changes_from === 'Classie Dagroosterbeheer') {
        next({ path: '/drb/today' });
    } else {
        next({ path: '/dashboard' });
    }
};

const onEmptySession = (callback, next, to) => {
    store.dispatch('Auth/startSession')
        .then(() => {
            callback();
        })
        .catch(() => {
            next({
                path: '/login',
                query: { redirect: to.path },
            });
        });
};

router.beforeEach(async (to, from, next) => {
    if (!to.matched.length) {
        if (to.path === '/') {
            if (store.getters['Auth/isAuthenticated']) {
                onEmptyLink(next);
            } else {
                onEmptySession(() => {
                    onEmptyLink(next);
                }, next, to);
            }
        } else {
            next({ path: '/' });
        }
    }

    if (!to.matched.every((page) => page.meta.guest)) { // Page requires auth
        if (store.getters['Auth/isAuthenticated']) {
            next();
            meta(to);
        } else {
            onEmptySession(() => {
                next();
                meta(to);
            }, next, to);
        }
    } else {
        next();
        meta(to);
    }
});

router.onError((e) => {
    console.log('error');
    router.replace({ path: '/', query: { fresh: Math.floor(Math.random() * 1000) }, params: { error: e } });
});

export default router;
