<template>
    <div id="app">
        <transition name="slide-down">
            <Nav class="--fixed" v-if="isAuthenticated" brand_to="/" brand_img="/img/classie_icon.svg">
                <template slot="left">
                    <li><router-link to="/" :class="{ '--active': ['drb', 'dashboard'].includes($route.name) }" exact>Dashboard</router-link></li>
                    <li><router-link to="/schedules">{{ $t('schedules.item_plural') }}</router-link></li>
                    <li v-if="$store.getters['Auth/rights']('exams')"><router-link to="/exams">{{ $t('exams.item_plural') }}</router-link></li>
                    <li class="--hoverable">
                        <router-link to="/content">{{ $t('content.content') }}</router-link>
                        <ul>
                            <li><router-link to="/content/monitors">{{ $t('content.monitors_plural') }}</router-link></li>
                            <li><router-link to="/content/cards">{{ $t('content.cards_plural') }}</router-link></li>
                            <hr v-if="$store.getters['Auth/rights']('basic', 'send_push')">
                            <li v-if="$store.getters['Auth/rights']('basic', 'send_push')"><router-link to="/content/push_send">{{ $t('content.pushnotification_plural') }}</router-link></li>
                        </ul>
                    </li>
                    <li v-if="$store.getters['Auth/rights']('basic', 'manage_settings')"><router-link to="/settings/school">{{ $t('settings.settings_plural') }}</router-link></li>
                    <li v-else><router-link to="/settings/users">{{ $t('settings.settings_plural') }}</router-link></li>
                    <li><a href="https://docs.classie.eu" target="_blank">Support</a></li>
                </template>
                <template slot="nav_right">
                    <li v-if="departments.length">
                        <Dropdown :small="true" icon="false">
                            <template v-slot:trigger>
                                {{ selectedDepartmentName }}
                                <icon class="mdi-domain"></icon>
                            </template>
                            <div
                                v-if="$store.state.school.settings.departments.length === departments.length"
                                @click="$store.dispatch('chooseDepartment', '')"
                                :class="{ '--active': !department.id }"
                            >
                                {{ $t('departments.all') }}
                            </div>
                            <div
                                v-for="(dep, index) in departments"
                                :key="'department_choice_' + index"
                                @click="$store.dispatch('chooseDepartment', dep.id)"
                                :class="{ '--active': +dep.id === +department.id }"
                            >
                                {{ dep.name }}
                            </div>
                        </Dropdown>
                    </li>
                    <li v-if="$store.getters['Auth/rights']('day_scheduling', 'change_lessons') && showPublishButton">
                        <button class="button button--publish --small" @click="openPublisher" :class="{ '--neutral': unpublishedChanges === 0 }" :title="unpublishedChanges+' '+$t('drb.unpublished_changes')">
                            {{ $t('actions.publish') }} <icon class="mdi-open-in-new"></icon>
                        </button>
                    </li>
                    <li><router-link to="/logout/" class="button --neutral --icon --small"><icon class="mdi-lock-open-outline"></icon></router-link></li>
                </template>
            </Nav>
        </transition>
        <main>
            <router-view/>
        </main>

        <LoadingFullScreen v-if="isAuthenticated">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill="#B23159" d="M7 19h10V4H7v15zm-5-2h4V6H2v11zM18 6v11h4V6h-4z"/>
            </svg>
        </LoadingFullScreen>

        <Publisher v-if="showPublisher" />

        <Toasts />
    </div>
</template>

<script>
// import serviceWorker from '@/mixins/serviceWorker';
import Nav from './components/Nav.vue';
import LoadingFullScreen from './components/LoadingFullScreen.vue';

export default {
    name: 'App',
    components: {
        Dropdown: () => import('@/components/Dropdown.vue'),
        LoadingFullScreen,
        Nav,
        Publisher: () => import(/* webpackChunkName: "Publisher" */ '@/views/modals/Publisher.vue'),
    },
    // mixins: [serviceWorker],
    created() {
        if (window.navigator && navigator.serviceWorker) {
            navigator.serviceWorker.getRegistrations()
                .then((registrations) => {
                    // eslint-disable-next-line
                    for (const registration of registrations) {
                        registration.unregister();
                    }
                });
        }
    },
    methods: {
        openPublisher() {
            this.$store.commit('DRB/togglePublisher', true);
        },
    },
    computed: {
        department() {
            return this.$store.getters.department;
        },

        departments() {
            return this.$store.getters.departments;
        },

        selectedDepartmentName() {
            if (this.department && this.department.name) {
                return `${this.department.name.substr(0, 10)}..`;
            }
            return this.$t('departments.plural');
        },

        unpublishedChanges() {
            return this.$store.getters['DRB/unpublishedAmount'];
        },

        showPublisher() {
            return this.$store.getters['DRB/showPublisher'];
        },

        showPublishButton() {
            const routename = this.$route.name;
            if (!routename || typeof routename !== 'string') {
                return false;
            }
            const unifiedRouteName = routename.replace(/_/g, '-');
            const routeNameSections = unifiedRouteName.split('-');
            return ['dashboard', 'schedules', 'drb'].includes(routeNameSections[0]);
        },

        isAuthenticated() {
            return this.$store.getters['Auth/isAuthenticated'];
        },
    },
};
</script>

<style>
    @import "~@mdi/font/css/materialdesignicons.min.css";
    @import "assets/css/modules.css";
    @import "assets/css/tags.css";
    @import "assets/css/style.css";
    @import "assets/css/colors.css";
    @import "assets/css/animations.css";
    @import "../public/css/print_exams.css";
</style>
