<template>
    <div class="toasts"
        tabindex="-1"
        @keydown.esc="keyboardEvent($event, 'esc')"
        @keydown.n="keyboardEvent($event, 'n')"
        @keydown.enter="keyboardEvent($event, 'enter')"
        @keydown.y="keyboardEvent($event, 'y')"
    >
        <transition-group name="fade">
            <div class="toast" v-for="toast in toasts" :class="toast.options.classes" @click="click(toast)" :key="toast.id">
                {{ toast.msg }}

                <div v-if="toast.options.actions" class="toast__actions">
                    <button
                        v-for="(action, index) in toast.options.actions"
                        :key="'toastaction_' + index"
                        type="button"
                        @click.stop="actionclick(action, toast)"
                        :class="action.class"
                    >
                        {{ action.name }}
                    </button>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import events from './events';

export default {
    name: 'Toaster',
    data() {
        return {
            toasts: {},
        };
    },
    mounted() {
        events.$on('addToast', this.add);
    },
    methods: {
        add(toast) {
            this.$set(this.toasts, toast.id, toast);
            this.$el.focus();

            if (toast.options.close !== 'click') {
                this.startRemoveTimer(toast.id, toast.options.timeout);
            }
        },

        click(item) {
            this.remove(item.id, 'click');
        },

        actionclick(action, toast) {
            this.remove(toast.id, action.action, action.cancel || false);
        },

        keyboardEvent(evt, key) {
            if (Object.keys(this.toasts).length) {
                const lastToast = Object.values(this.toasts).reduce((acc, cur) => {
                    if (!acc.id || cur.created < acc.created) { return cur; }
                    return acc;
                }, {});
                this.remove(lastToast.id, 'keyboard', ['esc', 'n'].includes(key));

                evt.cancelBubble = true;
                evt.returnValue = false;
                return false;
            }
            return true;
        },

        remove(id, trigger, cancel) {
            if (!id || !this.toasts[id]) { return false; }
            if (cancel) {
                this.toasts[id].reject({ type: 'cancel', action: trigger });
            } else {
                this.toasts[id].resolve({ type: 'remove', action: trigger });
            }
            this.$delete(this.toasts, id);
            return true;
        },

        startRemoveTimer(id, timeout) {
            setTimeout(() => {
                this.remove(id, 'timer');
            }, timeout);
        },
    },
};
</script>

<style>
    .toasts {
        position: fixed; bottom: 1.6rem; left: 1.6rem;
        max-width: 90vw; z-index: 150;
        --default-toast-color: var(--color-white, #fff);
        --default-toast-border-radius: var(--radius, 0.2rem);
        --default-toast-bg: var(--color-black, #000);
        --default-toast-padding-h: 1.2rem;
        --default-toast-padding-v: 0.8rem;
    }
        .toasts:focus { outline: none; }
    .toast {
        background-color: var(--toast-bg, var(--default-toast-bg));
        color: var(--toast-color, var(--default-toast-color));
        padding: var(--toast-padding-v, var(--default-toast-padding-v)) var(--toast-padding-h, var(--default-toast-padding-h));
        display: flex;
        align-items: center;
        margin-top: 0.2rem;
        border-radius: var(--toast-border-radius, var(--default-toast-border-radius));
        cursor: pointer;
        transition: transform 0.2s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
        .toast:hover { transform: scale(1.05); }
        .toast[class*='bg-'] {
            --toast-bg: var(--current-bg);
            --toast-color: var(--color-contrast-primary);
        }

        .toast__actions {
            display: flex;
            margin-right: calc(-1 * calc(var(--toast-padding-h, var(--default-toast-padding-h)) / 2));
            margin-left: calc(var(--toast-padding-h, var(--default-toast-padding-h)) / 2);
        }
            .toast__actions button + button {
                margin-left: 0.2rem;
            }
</style>
