/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
/**
 * API instance with support for api's and all other urls, distributed via bit
 * @class Request
 *
 * @param {object}          data
 * @param {boolean}         [data.abort_prefer_running=false] - Usually, new requests with the same name cancel the already running request. If set to true, new requests are cancelled and the first request can finish.
 * @param {(object|string)} [data.data] - request body, is converted to url parameters in case of GET
 * @param {string}          data.name - unique name for this request; used for aborting
 * @param {string}          data.method - must be uppercase
 * @param {('text'|'json'|'document'|'blob'|'arraybuffer')} [data.responsetype=text] - force encoding on response. Follows response headers otherwise
 * @param {string}          [data.url] - full request url
 * @param {string}          [data.endpoint] - path that gets prefixed with config.api.url
 *
 * @param {object}          [data.events]
 * @param {function}        [data.events.beforeload]
 * @param {function}        [data.events.afterload]
 * @param {function}        [data.events.onprogress] - sends percentage to function
 * @param {object}          [data.headers] - headers for this request
 * @param {boolean}         [data.headers_replace=false] - headers in config.api.headers are added to the headers in this request. Setting headers_replace to true only uses this headers
 * @param {boolean}         [data.loading=false] - if this request should trigger the stores loading object
 * @param {number}          [data.timeout=15] - timeout in seconds
 * @returns {object}        Promise object with request status, response and details
 *
 * overwritable in config
 * api_url, headers, timeout, events
 * changes: imported store and added store as config
 */
class Request {
    constructor(data) {
        const config = { api: {} };
        if (window.config && window.config.api) { config.api = window.config.api; }
        this.config = config;

        // Configurable values
        this.abort_prefer_running = data.abort_prefer_running || false;
        this.data = data.data || {};
        this.endpoint = data.endpoint;
        this.events = {}; // is assigned later
        this.headers = data.headers || {};
        this.headers_replace = data.headers_replace || false;
        this.loading = data.loading || false;
        this.method = data.method;
        this.name = data.name || '';
        this.timeout = data.timeout || config.api.timeout || 15;
        this.url = data.url;
        this.responsetype = data.responsetype || null;

        // You can't touch this - pa da da dam
        this.api_url = this.config.api.url || '';
        if (!this.config.api.events) { this.config.api.events = {}; }
        Object.assign(this.events, this.config.api.events, data.events);

        this.retries = 0;
        this.requestname = `api_${this.name}`;

        // Check for requireds
        if (!this.url && !this.endpoint) { throw new Error('No target specified. Please add an url or an endpoint'); }
        if (this.endpoint && !this.api_url) { throw new Error('No api-url specified in config'); }
        if (!this.name) { throw new Error('Request does not have a name'); }
        if (this.responsetype && !['json', 'text', 'document', 'blob', 'arraybuffer'].includes(this.responsetype)) { throw new Error('Invalid responsetype specified'); }
    }

    data_json() {
        if (typeof this.data === 'object') {
            this.data = JSON.stringify(this.data, (k, v) => (v === undefined ? null : v));
            // this.headers['Content-type'] = 'application/json';
        }
    }

    data_formdata_to_object() {
        const result = {};
        for (const pair of this.data.entries()) {
            if (result[pair[0]]) {
                result[pair[0]] += `,${pair[1]}`;
            } else {
                // eslint-disable-next-line prefer-destructuring
                result[pair[0]] = pair[1];
            }
        }
        this.data = result;
    }

    data_urlparams() {
        let data;
        if (typeof this.data === 'object') {
            data = Object.entries(this.data).map((e) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&');
        } else if (typeof this.data === 'string') {
            data = this.data;
        }
        if (data) {
            this.url += `?${data}`;
        }
    }

    header() {
        if (!this.headers_replace && this.config.api.headers) {
            Object.assign(this.headers, this.config.api.headers);
        }
    }

    init() {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;

            const continue_request = this.prepare();
            if (continue_request) {
                this.request();
            }
        });
    }

    on_load() {
        const request = window[this.requestname];
        if (request.readyState !== 4) { return; }
        const responseHeaders = 'getAllResponseHeaders' in request ? this.parse_headers(request.getAllResponseHeaders()) : null;

        const result = {
            data: request.response,
            status: request.status === 1223 ? 204 : request.status,
            statusText: request.status === 1223 ? 'No Content' : request.statusText,
            headers: responseHeaders,
            config: this,
            request,
        };
        if (result.status >= 200 && result.status < 300) {
            if (!this.responsetype && result.data) {
                this.process_response(result);
                window[this.requestname] = null;
            } else {
                this.resolve(result);
                window[this.requestname] = null;
            }
        } else {
            this.reject(result);
            window[this.requestname] = null;
        }
    }

    on_abort() {
        if (this.config.dev) { console.warn(`Running ${this.name} request aborted.`, this); }
    }

    on_error() {
        if (this.config.dev) { console.warn(`Network error for ${this.name}`, this); }
        this.reject(new Error('Network error'));
        window[this.requestname] = null;
    }

    on_loadstart() {
        if (this.loading && window.store) {
            window.store.commit('loadStart', this.name);
        }
        if (this.events.beforeload) { this.events.beforeload(); }
    }

    on_loadend() {
        if (this.loading && window.store) {
            window.store.commit('loadEnd', this.name);
        }
        if (this.events.afterload) { this.events.afterload(); }
    }

    on_progress(evt) {
        if (this.events.onProgress) {
            if (evt.lengthComputable) {
                const progress = `${Math.floor((evt.loaded / evt.total) * 100)}%`;
                this.events.onProgress(progress);
            }
        }
    }

    on_timeout() {
        if (this.config.dev) { console.warn(`Timeout of ${this.timeout}s exceeded for ${this.name}`, this); }
        this.reject(new Error('Timeout'));
        window[this.requestname] = null;
    }

    parse_headers(headers) {
        const parsed = {};
        headers.split('\n').forEach((line) => {
            const i = line.indexOf(':');
            const key = line.substr(0, i).toLowerCase().trim();
            const val = line.substr(i + 1).trim();

            if (key) {
                if (key === 'set-cookie') {
                    parsed[key] = (parsed[key] ? parsed[key] : []).concat([val]);
                } else {
                    parsed[key] = parsed[key] ? `${parsed[key]}, ${val}` : val;
                }
            }
        });
        return parsed;
    }

    prepare() {
        if (this.endpoint) { this.url = this.api_url + this.endpoint; }

        if (['POST'].includes(this.method)) {
            if (this.data instanceof FormData === false && this.data) { this.data_json(); }
        } else if (['PATCH'].includes(this.method)) {
            if (this.data instanceof FormData) {
                this.data_formdata_to_object();
            }
            this.data_json();
        } else if (['DELETE', 'GET'].includes(this.method)) {
            if (this.data instanceof FormData) {
                this.data_formdata_to_object();
            }
            if (this.data) { this.data_urlparams(); }
        }

        if (window[this.requestname] != null) {
            if (this.abort_prefer_running) {
                console.warn(`Running ${this.name} request, new request aborted`);
                return false;
            }
            if (this.config.dev) { console.warn(`Running ${this.name} request aborted`); }
            window[this.requestname].abort();
            window[this.requestname] = null;
        }

        this.header();
        return true;
    }

    process_response(result) {
        try {
            const ctype = result.headers['content-type'];
            if (ctype && result.data) {
                switch (ctype) {
                case 'application/json':
                    result.data = JSON.parse(result.data);
                    break;

                default:
                }
            }

            this.resolve(result);
        } catch (e) {
            if (this.config.dev) { console.warn('Could not parse response:', e, result); }
            this.reject(new Error('Could not parse response'));
            throw new Error('Could not parse response');
        }
    }

    request() {
        window[this.requestname] = new XMLHttpRequest();
        window[this.requestname].open(this.method, this.url, true);
        window[this.requestname].timeout = this.timeout * 1000;
        if (this.responsetype) {
            window[this.requestname].responseType = this.responsetype;
        }

        Object.keys(this.headers).forEach((key) => {
            window[this.requestname].setRequestHeader(key, this.headers[key]);
        });

        window[this.requestname].onabort = this.on_abort.bind(this);
        window[this.requestname].upload.onprogress = this.on_progress.bind(this);
        window[this.requestname].onload = this.on_load.bind(this);
        window[this.requestname].onerror = this.on_error.bind(this);
        window[this.requestname].ontimeout = this.on_timeout.bind(this);
        window[this.requestname].onloadstart = this.on_loadstart.bind(this);
        window[this.requestname].onloadend = this.on_loadend.bind(this);

        if (['GET', 'DELETE'].includes(this.method)) {
            window[this.requestname].send();
        } else {
            window[this.requestname].send(this.data);
        }
    }
}

/**
 * @method api
 * @param {object}  params
 * @memberof Request
 * @example
 * api.get(params)
 * api['get'](params)
 * api(params) - must include a method-parameter this way
 */
function api(params = {}) {
    if (!params.method) { params.method = 'GET'; }

    const request = new Request(params);
    return request.init();
}
const methods = {
    get: (params) => {
        params.method = 'GET';
        return api(params);
    },

    delete: (params) => {
        params.method = 'DELETE';
        return api(params);
    },

    post: (params) => {
        params.method = 'POST';
        return api(params);
    },

    patch: (params) => {
        params.method = 'PATCH';
        return api(params);
    },
};
Object.assign(api, methods);

export { api };
export default api;
