import Vue from 'vue';
import { api } from '@/plugins/api';
import apiErrors from '@/plugins/apiErrorhandling';
import { remove_keys_from_object } from '@/assets/js/helpers';
import i18n from '@/i18n';

export default {
    namespaced: true,
    state: {
        data: {
            items: [],
            add: {
                devices: '',
                drb: {
                    send_weekly_overview: false,
                },
                email: '',
                lang: 'nl',
                name: '',
                rights: {
                    change_lessons: true,
                    manage_monitors: true,
                    manage_notices: true,
                    manage_schedules: true,
                    manage_settings: true,
                    send_push: true,
                    departments: [],
                },
                two_factor: 0,
            },
            add_allowed: false,
            search: {
                keys: ['name'],
                placeholder: i18n.t('search.name'),
            },
        },
        dont_save: ['self'],
    },
    getters: {
        data: (state) => state.data,
        users: (state) => state.data.items,
    },
    mutations: {
        storeUsers(state, data) {
            state.data.items = data;
        },

        removeUsers(state, ids) {
            ids.forEach((id) => {
                const index = state.data.items.findIndex((x) => x.id === id);
                state.data.items.splice(index, 1);
            });
        },

        addUser(state, data) {
            state.data.items.push(data);
        },

        updateUser(state, data) {
            const item = state.data.items.find((it) => it.id === data.id);
            if (item) {
                Object.assign(item, { ...data });
            }
        },

        setAddRights(state, data) {
            state.data.add_allowed = data;
        },
    },
    actions: {
        get({ commit, rootGetters, rootState }) {
            commit('setAddRights', rootGetters.rights('basic', 'manage_settings'));

            api.get({
                name: 'users',
                endpoint: `schools/${rootState.settings.schoolid}/users`,
                loading: true,
            }).then((r) => {
                if (r.status === 200) {
                    commit('storeUsers', r.data.results);
                } else {
                    console.warn('GET users call returned incorrect status', r);
                }
            });
        },

        patch({ commit, rootState }, data) {
            return new Promise((resolve, reject) => {
                let savedata = { ...data };
                if (savedata.self) {
                    // TODO update self
                    commit('Auth/updateUser', savedata, { root: true });
                }
                savedata = remove_keys_from_object(savedata, this.dont_save);

                api.patch({
                    name: 'patch_user',
                    endpoint: `schools/${rootState.settings.schoolid}/users/${data.id}`,
                    data: savedata,
                    loading: true,
                }).then((r) => {
                    Vue.toast({ msg: i18n.t('toasts.save_success') });
                    commit('updateUser', data);

                    resolve(r);
                }).catch((err) => {
                    apiErrors.methods.apiError(err);
                    reject(err);
                });
            });
        },

        post({ commit, rootState }, data) {
            return new Promise((resolve, reject) => {
                let savedata = { ...data };
                savedata = remove_keys_from_object(savedata, this.dont_save);

                api.post({
                    name: 'post_user',
                    endpoint: `schools/${rootState.settings.schoolid}/users/`,
                    data: savedata,
                    loading: true,
                }).then((r) => {
                    Vue.toast({ msg: i18n.t('toasts.save_success') });
                    commit('addUser', r.data.data);
                    resolve(r);
                }).catch((err) => {
                    apiErrors.methods.apiError(err);
                    reject(err);
                });
            });
        },

        delete({ commit, rootState }, ids) {
            return new Promise((resolve, reject) => {
                api.delete({
                    name: 'delete_users',
                    endpoint: `schools/${rootState.settings.schoolid}/users/${ids.join(',')}`,
                    loading: true,
                }).then(() => {
                    commit('removeUsers', ids);
                    Vue.toast({ msg: i18n.t('toasts.delete_success') });
                    resolve();
                }).catch((err) => {
                    Vue.toast({ msg: i18n.t('toasts.delete_error'), classes: 'bg-danger' });
                    console.log(err);
                    reject();
                });
            });
        },

        processRights({ commit, rootState, rootGetters }) {
            return new Promise((resolve) => {
                const { rights } = rootGetters['Auth/user'];
                const org = rootState.school || {};

                const rights_processed = {
                    monitor: {
                        enabled: !!org.monitor,
                        permissions: {
                            manage_monitors: !!rights.manage_monitors,
                        },
                    },
                    day_scheduling: {
                        enabled: !!org.day_scheduling,
                        permissions: {
                            change_lessons: !!rights.change_lessons,
                            manage_notices: !!rights.manage_notices,
                        },
                    },
                    exams: {
                        enabled: !!org.exam_scheduling,
                        permissions: {
                            manage_exams: true,
                        },
                    },
                    basic: {
                        enabled: true,
                        permissions: {
                            manage_schedules: !!rights.manage_schedules,
                            manage_settings: !!rights.manage_settings,
                            send_push: !!rights.send_push,
                        },
                    },
                };

                commit('Auth/setRights', rights_processed, { root: true });
                resolve();
            });
        },
    },
};
