import Vue from 'vue';
import Vuex from 'vuex';
import apiErrors from '@/plugins/apiErrorhandling';
import * as Sentry from '@sentry/vue';
import { api } from '@/plugins/api';

import Auth from '@/plugins/Auth';
import i18n from '@/i18n';
import DRB from '@/store/DRB';
import Exams from '@/store/Exams';
import Items from '@/store/Items';
import Users from '@/store/Users';
import SSE from '@/store/SSE';
import Errors from '@/store/Errors';

Vue.use(Vuex);

const apiUrl = process.env.NODE_ENV === 'development' && window.location.hostname !== 'live.localhost' ? process.env.VUE_APP_API_URL_DEV : process.env.VUE_APP_API_URL;

window.config = {
    api: {
        url: `https://${apiUrl}/`,
        file_location: 'https://cdn.classie.eu/',
        headers: {
            'app-version': APPLICATION_VERSION,
        },
        timeout: 60,
    },
};

const store = new Vuex.Store({
    modules: {
        Auth,
        DRB,
        Exams,
        Items,
        Users,
        Errors,
        SSE,
    },
    state: {
        app: {
            department: null,
            loading: false,
            loading_names: [],
            loading_fullscreen: {
                show: true,
                title: '',
                message: '',
            },
            name: 'Classie Dashboard',
            name_separator: ' - ',
            nav_opened: false,
            version: APPLICATION_VERSION,
        },
        api: {
            url: `https://${apiUrl}/`,
            file_location: 'https://cdn.classie.eu/',
            headers: {
                'app-version': APPLICATION_VERSION,
            },
            timeout: 60,
        },
        data: {
            countries: [
                { id: 20, name: i18n.t('countries.belgium') },
                { id: 57, name: i18n.t('countries.germany') },
                { id: 75, name: i18n.t('countries.france') },
                { id: 166, name: i18n.t('countries.netherlands') },
            ],
            weekdays: [
                { id: 'Maandag', name: i18n.t('months.Monday') },
                { id: 'Dinsdag', name: i18n.t('months.Tuesday') },
                { id: 'Woensdag', name: i18n.t('months.Wednesday') },
                { id: 'Donderdag', name: i18n.t('months.Thursday') },
                { id: 'Vrijdag', name: i18n.t('months.Friday') },
                { id: 'Zaterdag', name: i18n.t('months.Saturday') },
                { id: 'Zondag', name: i18n.t('months.Sunday') },
            ],
        },
        timetables: {
            hours: {
                height: 8,
                heightunits: 'rem',
                toPixelRatio: 10,
            },
        },
        now: {
            timestamp: 0,
            time: 0,
        },
        settings: {
            schoolid: null,
        },
        school: {},
    },
    mutations: {
        loadStart(state, name) {
            state.app.loading_names.push(name);
            state.app.loading = true;
        },

        loadEnd(state, name) {
            const index = state.app.loading_names.indexOf(name);
            if (index !== -1) {
                state.app.loading_names.splice(index, 1);
            }

            if (state.app.loading_names.length === 0) {
                state.app.loading = false;

                if (state.app.loading_fullscreen && !state.app.loading_fullscreen.message) {
                    state.app.loading_fullscreen.show = false;
                    state.app.loading_fullscreen.title = '';
                    state.app.loading_fullscreen.message = '';
                }
            }
        },

        setAppData(state, data = {}) {
            state.settings.schoolid = data.schoolid;
        },

        userBugsnag(state, user) {
            Sentry.setContext('user', {
                id: user.id,
                name: user.name,
                lang: user.lang,
            });
            Sentry.setContext('school', {
                id: state.settings.schoolid,
                name: state.school.name,
            });
        },

        update_school_config(state, r) {
            if (r.school) {
                state.school = r.school;
                Sentry.setContext('school', {
                    id: state.settings.schoolid,
                    name: state.school.name,
                });
            }
            if (r.settings) { state.settings = r.settings; }
        },

        update_time(state) {
            state.now.timestamp = Math.floor(Date.now() / 1000);
            const now = new Date();
            const time = `${now.getHours()}${now.getMinutes() < 10 ? '0' : ''}${now.getMinutes()}`;
            state.now.time = +time;
        },

        nav(state, data) {
            if (data.action === 'toggle') {
                state.app.nav_opened = !state.app.nav_opened;
            } else if (data.action === 'close') {
                state.app.nav_opened = false;
            }
        },

        setDepartment(state, data) {
            state.app.department = +data;
            localStorage.setItem('departmentID', +data);
        },
    },
    actions: {
        chooseDepartment({ commit }, id) {
            commit('setDepartment', id);
        },

        restoreDepartment({ dispatch, getters, state }) {
            const savedDep = localStorage.getItem('departmentID');
            const deps = getters.departments || [];
            if (savedDep && deps.find((it) => it.id === +savedDep)) {
                // department exists
                dispatch('chooseDepartment', +savedDep);
            } else if (deps.length > 0 && deps.length !== state.school.settings.departments.length) {
                dispatch('chooseDepartment', deps[0].id);
            }
        },

        onLogin({ commit, dispatch }, applicationData) {
            return new Promise((resolve, reject) => {
                commit('setAppData', applicationData);

                Promise.all([
                    dispatch('getSchooldata'),
                    dispatch('Items/getMultiple', ['classes', 'students', 'teachers', 'rooms', 'subjects']),
                ])
                    .then(() => {
                        dispatch('Users/processRights').then(() => {
                            dispatch('SSE/init');
                            dispatch('DRB/getConflictAmount');
                            resolve();
                        });
                    })
                    .catch((err) => reject(err));
            });
        },

        onLogout({ dispatch, state }) {
            state.settings.schoolid = null;
            state.school = {};
            dispatch('SSE/close');
        },

        getSchooldata({ state, commit, dispatch }) {
            return new Promise((resolve, reject) => {
                api.get({ name: 'schoolconfig', endpoint: `schools/${state.settings.schoolid}/` })
                    .then((r) => {
                        if (r.data && r.data.status === 'success' && r.data.results && r.data.results.length) {
                            const data = { school: r.data.results[0] };
                            commit('update_school_config', data);
                            dispatch('restoreDepartment');
                            resolve();
                        } else {
                            throw new Error('Error getting school config', r);
                        }
                    }).catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },
    },
    getters: {
        hours: (state) => {
            try {
                return state.school.settings.lesson_hours[1];
            } catch (err) {
                return [];
            }
        },
        rights: (state, getters) => (mod, permission) => getters['Auth/rights'](mod, permission),
        activityreasons: () => ['Activiteit / Excursie'],
        schoolinfo: (state) => state.school || {},
        apiConfig: (state, getters) => {
            // note; this is defined in auth.js as well
            const data = state.api;
            data.headers = { ...data.headers, ...getters['Auth/header'] };
            return data;
        },
        departments: (state, getters) => {
            try {
                if (state.school.settings.departments) {
                    const userRights = getters['Auth/user'].rights;
                    if (!userRights.departments.length) {
                        return state.school.settings.departments;
                    }
                    return state.school.settings.departments.filter((it) => userRights.departments.includes(it.id));
                }
                return false;
            } catch (err) {
                return false;
            }
        },
        department: (state, getters) => {
            const selected = state.app.department || false;
            const deps = getters.departments;
            if (deps) {
                const find = deps.find((it) => +it.id === +selected);
                return find || false;
            }
            return false;
        },
        departmentName: (state, getters) => (id) => {
            const deps = getters.departments;
            if (id) {
                const find = deps.find((it) => it.id === id);
                if (find) {
                    return find.name;
                }
                return '';
            }
            return '';
        },
    },
});

window.store = store;

export default store;
