import Vue from 'vue';
import { api } from '@/plugins/api';
import apiErrors from '@/plugins/apiErrorhandling';
import { intersect, process_lesson, remove_keys_from_object } from '@/assets/js/helpers';
import i18n from '@/i18n';

export default {
    namespaced: true,
    state: {
        date: null,
        notices: {
            items: [],
            dont_save: [],
            add: {
                classes: [],
                rooms: [],
                teachers: [],
                date_from: null,
                date_to: null,
                hour_from: {},
                hour_to: {},
                name: '',
                reason: {},
                remarks: '',
                type: '',
            },
        },
        conflicts: {
            items: [],
            groups: {
                activities: [],
                other: [],
            },
            amount_unpublished: 0,
            latest_change: null,
        },
        publisher: {
            items: [],
            show: false,
        },
        scheduler: {
            show: false,
            data: {},
        },
    },
    getters: {
        notices: (state) => state.notices.items,

        notice: (state) => (id) => {
            if (id === 'add') {
                return state.notices.add;
            }
            id = +id;
            return state.notices.items.find((it) => it.id === id) || {};
        },

        conflicts: (state) => state.conflicts.items,

        activities: (state) => state.conflicts.groups.activities,

        not_activities: (state) => state.conflicts.groups.other,

        latestchange: (state) => state.conflicts.latest_change,

        unpublishedAmount: (state) => state.conflicts.amount_unpublished,

        unpublishedConflicts: (state) => state.publisher.items,

        showPublisher: (state) => state.publisher.show,

        scheduler: (state) => state.scheduler,

        solutions: (state, getters, rootState) => (names) => {
            const options = rootState.school.settings.quick_solutions;
            if (!options || options.length === 0) { return false; }

            let type = '';
            if (!names.teacher_full && !names.room) {
                type = 'teacher_room_absent';
            } else if (!names.teacher_full) {
                type = 'teacher_absent';
            } else if (!names.room) {
                type = 'room_absent';
            }
            let solutions = options.filter((it) => it.if[0] === type);
            solutions = solutions.map((it) => it.action);
            return solutions.sort((a, b) => {
                if (a.type[0] < b.type[0]) { return -1; }
                if (a.type[0] > b.type[0]) { return 1; }
                return 0;
            });
        },
    },
    mutations: {
        setDate(state, data) {
            state.date = data;
        },

        // Notices
        storeNotices(state, data) {
            state.notices.items = data;
        },

        patchNotice(state, data) {
            const noticeIndex = state.notices.items.findIndex((it) => it.id === data.id);
            state.notices.items[noticeIndex] = data;
        },

        deleteNotice() {
            // Notices are refreshed after this, so this doesn't do anything
        },

        // Conflicts & Publish
        storeConflicts(state, data) {
            state.conflicts.items = data.items;
            state.conflicts.groups.activities = data.activities;
            state.conflicts.groups.other = data.other;
        },

        storeConflictsConcept(state, data) {
            state.publisher.items = data;
        },

        publishedConflicts(state, data) {
            const affectedConflicts = state.conflicts.items.filter((it) => intersect(it.changeids, data).length > 0);
            for (let i = 0, len = affectedConflicts.length; i < len; i += 1) {
                affectedConflicts[i].concept = false;
            }
        },

        updateUnpublishedAmount(state, data) {
            state.conflicts.amount_unpublished = data;
        },

        updateLastChangedDate(state, data) {
            state.conflicts.latest_change = data;
        },

        togglePublisher(state, data) {
            state.publisher.show = data;
        },

        toggleScheduler(state, { show, data }) {
            state.scheduler.show = show;
            if (data) {
                state.scheduler.data = data;
            }
        },

        updateConflict(state, { newConflictdata, changeids }) {
            // NOTE, this does not work for activities
            changeids.forEach((id) => {
                const index = state.conflicts.items.findIndex((it) => it.changeids.includes(id));
                if (index > -1) {
                    const cdata = state.conflicts.items[index];
                    Vue.set(state.conflicts, index, { ...cdata, ...newConflictdata });
                }

                const gindex = state.conflicts.groups.other.findIndex((it) => it.changeids.includes(id));
                if (gindex > -1) {
                    const cdata = state.conflicts.groups.other[gindex];
                    Vue.set(state.conflicts.groups.other, gindex, { ...cdata, ...newConflictdata });
                }
            });
        },
    },
    actions: {
        setDate({ commit }, date) {
            if (date) {
                commit('setDate', date);
            }
            this.dispatch('DRB/getNotices');
            this.dispatch('DRB/getConflicts');
        },

        getNotices(context) {
            return new Promise((resolve, reject) => {
                const params = { date: context.state.date };
                const dep = context.rootGetters.department;
                if (dep && dep.id) {
                    params.department = dep.id;
                }

                api.get({
                    name: 'get_notices',
                    endpoint: `schools/${context.rootState.settings.schoolid}/notices/`,
                    data: params,
                    loading: true,
                }).then((r) => {
                    if (r.data && r.data.results) {
                        context.commit('storeNotices', r.data.results);
                    } else {
                        throw new Error('no results');
                    }
                }).catch((err) => {
                    apiErrors.methods.apiError(err);
                    context.commit('storeNotices', []);
                    reject(err);
                });
            });
        },

        updateNotice(context, data) {
            return new Promise((resolve, reject) => {
                let item = JSON.parse(JSON.stringify(data));
                const method = item.id ? 'patch' : 'post';
                if (item.id) {
                    context.commit('patchNotice', item);
                }
                item = remove_keys_from_object(item, context.state.notices.dont_save);

                const params = {
                    name: `save_notice_${item.id || ''}`,
                    endpoint: `schools/${context.rootState.settings.schoolid}/notices/`,
                    data: item,
                    loading: true,
                };
                if (item.id) { params.endpoint += `${item.id}/`; }

                api[method](params)
                    .then(() => {
                        if (context.state.date >= item.date_from && context.state.date <= item.date_to) { // Current date is in notice, refresh data
                            this.dispatch('DRB/getNotices');
                            this.dispatch('DRB/getConflicts');
                        }
                        resolve();
                    }).catch((err) => {
                        try {
                            const parsed = JSON.parse(err.data);
                            if (parsed && parsed.code === 37) {
                                reject(parsed);
                            } else {
                                throw new Error('not 37');
                            }
                        } catch (e) {
                            apiErrors.methods.apiError(err);
                            reject(err);
                        }
                    });
            });
        },

        deleteNotice(context, id) {
            const notice = context.state.notices.items.find((it) => it.id === id);
            return new Promise((resolve, reject) => {
                context.commit('deleteNotice', id);

                api.delete({
                    name: `delete_notice_${id}`,
                    endpoint: `schools/${context.rootState.settings.schoolid}/notices/${id}`,
                    loading: true,
                }).then(() => {
                    const response = {};
                    if (context.state.date >= notice.date_from && context.state.date <= notice.date_to) { // Current date is in notice, refresh data
                        this.dispatch('DRB/getNotices');
                        this.dispatch('DRB/getConflicts');
                    }
                    if (notice.date_from <= (Date.now() / 1000)) { // Notice starts before today
                        response.shorten = true;
                    }
                    resolve(response);
                }).catch((err) => {
                    apiErrors.methods.apiError(err);
                    reject(err);
                });
            });
        },

        substituteNotice(context, {
            conflicts, teacher, room, daterange,
        }) {
            return new Promise((resolve, reject) => {
                const params = {
                    name: 'substitute_notice',
                    endpoint: `schools/${context.rootState.settings.schoolid}/substitutions/`,
                    loading: true,
                    data: {
                        conflicts,
                        teacher,
                        room,
                    },
                };

                api.post(params)
                    .then(() => {
                        if (context.state.date >= daterange[0] && context.state.date <= daterange[1]) { // Current date is in notice, refresh data
                            this.dispatch('DRB/getNotices');
                            this.dispatch('DRB/getConflicts');
                        }
                        resolve();
                    }).catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        getConflicts({
            state,
            rootState,
            rootGetters,
            dispatch,
            commit,
        }, data = {}) {
            const params = {};
            if (data.concept) {
                params.concept = true;
            } else {
                params.date = state.date;
            }
            const dep = rootGetters.department;
            if (dep && dep.id) {
                params.department = dep.id;
            }
            if (data.limit) { params.limit = data.limit; }
            return new Promise((resolve, reject) => {
                api.get({
                    name: 'get_conflicts',
                    endpoint: `schools/${rootState.settings.schoolid}/conflicts/`,
                    data: params,
                    loading: true,
                }).then((r) => {
                    if (r.data && r.data.results) {
                        if (params.concept) {
                            dispatch('groupConflictsByDay', r.data.results);
                        } else {
                            Promise.all([
                                dispatch('groupConflicts', { data: r.data.results, type: 'activities' }),
                                dispatch('groupConflicts', { data: r.data.results, type: 'not-activities' }),
                            ]).then((values) => {
                                const conflicts = {
                                    items: r.data.results,
                                    activities: values[0],
                                    other: values[1],
                                };

                                commit('storeConflicts', conflicts);
                            });
                        }
                    } else {
                        throw new Error('no results');
                    }
                }).catch((err) => {
                    if (err && err.message !== 'Network error') {
                        apiErrors.methods.apiError(err);
                    }
                    commit('storeConflicts', []);
                    reject(err);
                });
            });
        },

        groupConflicts(context, { data, type }) {
            if (type === 'activities') {
                return data.filter((it) => it.activity && it.notices.some((not) => not.type === 'activity'));
            }
            if (type === 'not-activities') {
                return data.filter((it) => !it.activity);
            }
            return [];
        },

        patchConflict({ commit, rootState }, data) {
            const changeids = data.map((it) => it.id);
            const { id, ...newConflictdata } = data[0];
            commit('updateConflict', { newConflictdata, changeids });

            return new Promise((resolve, reject) => {
                api.patch({
                    name: `patch_conflicts_${data.map((it) => it.id).join('_')}`,
                    endpoint: `schools/${rootState.settings.schoolid}/conflicts/`,
                    data,
                    loading: true,
                }).then(() => {
                    resolve();
                    commit('updateConflict', { newConflictdata: data, changeids });
                }).catch((err) => {
                    apiErrors.methods.apiError(err);
                    reject(err);
                });
            });
        },

        // OPTIMIZE move to SSE
        getConflictAmount({
            state,
            rootState,
            commit,
            rootGetters,
            dispatch,
        }) {
            const interval = 45000;
            clearTimeout(window.getConflictsInterval);
            if (rootGetters.rights('day_scheduling', 'change_lessons')) {
                api.get({
                    name: 'get_unpublished_conflicts_amount',
                    endpoint: `schools/${rootState.settings.schoolid}/publish/`,
                }).then((r) => {
                    if (r && r.data && r.data.results) {
                        if (r.data.results.amount > -1 && state.conflicts.amount_unpublished !== r.data.results.amount) {
                            commit('updateUnpublishedAmount', r.data.results.amount);
                        }
                        if (r.data.results.last_updated > -1 && state.conflicts.latest_change !== r.data.results.last_updated) {
                            commit('updateLastChangedDate', r.data.results.last_updated);
                        }
                    }
                }).catch((err) => {
                    console.log(err);
                }).then(() => {
                    window.getConflictsInterval = setInterval(() => {
                        dispatch('getConflictAmount');
                    }, interval);
                });
            } else {
                window.getConflictsInterval = setInterval(() => {
                    dispatch('getConflictAmount');
                }, interval);
            }
        },

        groupConflictsByDay({ commit }, data) {
            const groups = {};
            for (let i = 0, len = data.length; i < len; i += 1) {
                const conf = data[i];
                const day = conf.day * 1000;
                if (conf.changeid || conf.lessonid) {
                    conf.lesson = process_lesson(conf);
                    if (typeof groups[day] !== 'object') {
                        groups[day] = [conf];
                    } else {
                        groups[day].push(conf);
                    }
                }
            }

            commit('storeConflictsConcept', groups);
        },

        publishConflicts(context, data) {
            return new Promise((resolve, reject) => {
                api.post({
                    name: `publish_conflicts_${Date.now()}`,
                    endpoint: `schools/${context.rootState.settings.schoolid}/publish/`,
                    data: { changeids: data.changeids },
                    loading: true,
                }).then((r) => {
                    if (r.data && r.data.results) {
                        context.commit('publishedConflicts', data.changeids);
                        Vue.toast({ msg: i18n.t('toasts.publish_success') });
                        if (r.data.results.amount > -1) {
                            context.commit('updateUnpublishedAmount', r.data.results.amount);
                        }
                        if (data.origin === 'publisher' && r.data.results.exports) {
                            context.dispatch('openExportPopups', r.data.results.exports);
                        }
                        resolve(r);
                    } else {
                        throw new Error('not published');
                    }
                }).catch((err) => {
                    Vue.toast({ msg: i18n.t('toasts.publish_error'), classes: 'bg-danger' });
                    apiErrors.methods.apiError(err);
                    reject(err);
                });
            });
        },

        openExportPopups(context, data) {
            for (let i = 0, len = data.length; i < len; i += 1) {
                const export_window = window.open(data[i]);
                try {
                    export_window.focus();
                } catch (err) {
                    console.log(err);
                    Vue.toast({ msg: i18n.t('toasts.popup_blocked'), classes: 'bg-danger' });
                }
            }
        },
    },
};
