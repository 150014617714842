import Vue from 'vue';
import { api } from '@/plugins/api';
import apiErrors from '@/plugins/apiErrorhandling';
import { remove_keys_from_object, remove_empty_characters } from '@/assets/js/helpers';
import i18n from '@/i18n';

export default {
    namespaced: true,
    state: {
        periods: {
            active: null,
            add: {
                name: '',
                classes: [],
                rooms: [],
                teachers: [],
                period: [],
                excludeddays: [],
                department: null,
                times: [{ from: 0 }, { from: 0 }],
                preferences: {
                    prefer_subjectteacher: false,
                    prefer_classteacher: false,
                    disallow_subjectteacher: true,
                    ignore_workhours: true,
                    jokers: 0,
                },
            },
            dont_save: ['exam_amount', 'exam_amount_planned', 'exam_amount_published'],
            items: [],
            stats: [],
            optimizing: false,
        },
        exams: {
            search: {
                keys: ['name'],
                placeholder: i18n.t('search.name'),
            },
            items: [],
            add: {
                name: '',
                subject: null,
                rooms_amount: 1,
                surveillors_per_room: 1,
                duration: null,
                extension: 0,
                planned: {
                    day: 0,
                    from: 0,
                    to: 0,
                    combinations: [],
                },
                preferred_days: [],
                preferred_rooms: [],
                preferred_teachers: [],
                concept: 1,
                classids: [],
            },
            add_allowed: false,
            dont_save: ['concept'],
        },
        modals: {
            choosePeriod: false,
            addPeriod: {
                show: false,
                id: null,
            },
            editExam: {
                show: false,
                id: null,
            },
        },
    },
    getters: {
        object: (state) => (type) => state[type],
        items: (state) => (type) => state[type].items || [],
        item: (state) => (type, ids) => {
            if (typeof ids === 'string' || typeof ids === 'number') {
                if (ids === 'add') {
                    return state[type].add;
                }
                return state[type].items.find((it) => ids === it.id) || {};
            }
            return state[type].items.filter((it) => ids.includes(it.id)) || [];
        },
        modals: (state) => state.modals,
        period: (state) => state.periods.items.find((it) => it.id === state.periods.active) || {},
        periodStats: (state) => state.periods.stats || [],
        isPeriodOptimizing: (state) => state.periods.optimizing,
        examsForClass: (state, getters) => (classids = []) => {
            const exams = [...getters.items('exams')];
            if (classids.length) {
                const result = [];
                exams.forEach((it) => {
                    if (it.classids.includes(classids[0])) {
                        result.push(it);
                    }
                });
                return result;
            }
            return exams;
        },
        status: (state, getters) => {
            const exams = getters.items('exams') || [];

            return {
                amount: exams.length,
                amount_planned: exams.filter((it) => it.planned && it.planned.day).length,
            };
        },
    },
    mutations: {
        addPeriodModal(state, { show, id }) {
            state.modals.addPeriod.show = show;
            if (id) {
                state.modals.addPeriod.id = id;
            }
            if (show) {
                state.modals.choosePeriod = false;
            }
        },

        choosePeriodModal(state, data) {
            state.modals.choosePeriod = data;
            if (data) {
                state.modals.addPeriod.show = false;
            }
        },

        editExamModal(state, { show, id }) {
            state.modals.editExam.show = show;
            if (id) {
                state.modals.editExam.id = id;
            }
        },

        setNewPeriodTimes(state, data) {
            state.periods.add.times = data;
        },

        setPeriod(state, id) {
            state.periods.active = id;
        },

        setPeriodStats(state, items) {
            state.periods.stats = items;
        },

        publishPeriod(state, { id, publish }) {
            const period = state.periods.items.find((it) => it.id === id);
            if (period) {
                period.published = publish === true;
            }
        },

        storeItems(state, { type, items }) {
            if (type === 'periods' && items.length) {
                items.forEach((period) => {
                    period.classes.forEach((periodclass) => {
                        if (periodclass.item) {
                            periodclass.item = remove_empty_characters(periodclass.item, ['name']);
                        }
                    });
                    if (period.classes && period.classes.length > 0) {
                        period.classes.sort((a, b) => (a.item.name > b.item.name ? 1 : -1));
                    }
                });
            }
            state[type].items = items;
        },

        storeItem(state, { type, id, item }) {
            const current = state[type].items.findIndex((it) => it.id === id);
            if (type === 'periods' && item.classes && item.classes.length) {
                item.classes.forEach((periodclass) => {
                    if (periodclass.item) {
                        periodclass.item = remove_empty_characters(periodclass.item, ['name']);
                    }
                });
                item.classes.sort((a, b) => (a.item.name > b.item.name ? 1 : -1));
            }
            if (current > -1) {
                Vue.set(state[type].items, current, item);
            } else {
                state[type].items.push(item);
            }
        },

        addItem(state, { type, item }) {
            state[type].items.push(item);
        },

        updateItem(state, { type, item }) {
            const obj = state[type].items.find((it) => it.id === item.id);
            if (obj) {
                Object.assign(obj, { ...item });
            }
        },

        removeItems(state, { type, ids }) {
            ids.forEach((id) => {
                const index = state[type].items.findIndex((x) => x.id === id);
                state[type].items.splice(index, 1);
            });
        },

        setAddRights(state, data) {
            state.exams.add_allowed = data;
        },

        optimizingPeriod(state, data) {
            state.periods.optimizing = data;
        },
    },
    actions: {
        PeriodSet({ commit, dispatch, getters }, id) {
            commit('setPeriod', id);

            if (id) {
                commit('choosePeriodModal', false);
                if (!getters.items('periods').length) {
                    dispatch('PeriodsGet');
                }
                const params = {};
                if (router.currentRoute && router.currentRoute.name && router.currentRoute.name.startsWith('exams-overview')) {
                    params.individual = true;
                }
                dispatch('ExamsGet', params);
            } else {
                commit('choosePeriodModal', true);
            }
        },

        PeriodsGet({ commit, dispatch, rootState }, { id } = {}) {
            return new Promise((resolve, reject) => {
                api.get({
                    name: 'get_examperiods',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${id || ''}`,
                    loading: true,
                })
                    .then((r) => {
                        if (r.status === 200 && r.data && r.data.status === 'success') {
                            if (id) {
                                commit('storeItem', {
                                    type: 'periods',
                                    id,
                                    item: r.data.results[0],
                                });
                            } else {
                                commit('storeItems', { type: 'periods', items: r.data.results });
                                dispatch('PeriodsDefaultTime');
                                resolve(r.data.results);
                            }
                        } else {
                            throw new Error('Unexpected result', r);
                        }
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        PeriodsDefaultTime({ commit, rootGetters }) {
            const default_hours = rootGetters.hours;
            if (default_hours.length > 0) {
                const firstHour = default_hours.find((it) => it.hour === 1) || default_hours[0];
                const thirdHour = default_hours.find((it) => it.hour === 3) || default_hours[2];
                commit('setNewPeriodTimes', [{ from: firstHour.from }, { from: thirdHour.from }]);
            } else {
                Vue.toast({ msg: i18n.t('toasts.hours_incomplete'), classes: 'bg-danger' });
            }
        },

        PeriodsPatch({ dispatch, state, rootState }, { item }) {
            return new Promise((resolve, reject) => {
                let savedata = { ...item };
                savedata = remove_keys_from_object(savedata, state.periods.dont_save);

                api.patch({
                    name: 'patch_period',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${item.id}`,
                    data: savedata,
                    loading: true,
                })
                    .then((r) => {
                        Vue.toast({ msg: i18n.t('toasts.save_success') });
                        dispatch('PeriodsGet').then(() => {
                            resolve(r);
                        });
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        PeriodsPost({ dispatch, state, rootState }, { item }) {
            return new Promise((resolve, reject) => {
                let savedata = { ...item };
                savedata = remove_keys_from_object(savedata, state.periods.dont_save);

                api.post({
                    name: 'post_period',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/`,
                    data: savedata,
                    loading: true,
                })
                    .then((r) => {
                        Vue.toast({ msg: i18n.t('toasts.save_success') });
                        dispatch('PeriodsGet').then(() => {
                            resolve(r);
                        });
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        PeriodsDelete({ commit, rootState }, ids) {
            return new Promise((resolve, reject) => {
                api.delete({
                    name: 'delete_periods',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${ids.join(',')}`,
                    loading: true,
                })
                    .then(() => {
                        commit('removeItems', { type: 'periods', ids });
                        Vue.toast({ msg: i18n.t('toasts.delete_success') });
                        resolve();
                    })
                    .catch((err) => {
                        Vue.toast({ msg: i18n.t('toasts.delete_error'), classes: 'bg-danger' });
                        console.log(err);
                        reject();
                    });
            });
        },

        PeriodsCopy({ dispatch, rootState }, id) {
            return new Promise((resolve, reject) => {
                api.post({
                    name: 'post_period',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/`,
                    data: {
                        copy: true,
                        id,
                    },
                    loading: true,
                })
                    .then((r) => {
                        Vue.toast({ msg: i18n.t('toasts.save_success') });
                        dispatch('PeriodsGet').then(() => {
                            resolve(r);
                        });
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        PeriodGetStats({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                api.get({
                    name: 'get_period_stats',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${state.periods.active}/stats/`,
                    loading: true,
                })
                    .then((r) => {
                        if (r.data && r.data.status === 'success') {
                            commit('setPeriodStats', r.data.results);
                        } else {
                            throw new Error('Invalid response');
                        }
                    })
                    .catch((err) => {
                        Vue.toast({
                            msg: i18n.t('exams.toasts.stats_fetch_error'),
                            classes: 'bg-danger',
                        });
                        console.log(err);
                        reject();
                    });
            });
        },

        ExamsGet(
            {
                commit, state, rootState, rootGetters,
            },
            { individual } = {},
        ) {
            commit('setAddRights', rootGetters.rights('exams', 'manage_exams'));

            return new Promise((resolve, reject) => {
                const data = {
                    name: 'get_exams',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${state.periods.active}/exams/`,
                    data: {},
                    loading: true,
                };
                if (individual) { data.data.individual = individual; }
                api.get(data)
                    .then((r) => {
                        if (r.status === 200 && r.data && r.data.status === 'success') {
                            commit('storeItems', { type: 'exams', items: r.data.results });
                            resolve(r.data.results);
                        } else {
                            throw new Error('Unexpected result', r);
                        }
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        ExamsPatch({ commit, state, rootState }, { item }) {
            return new Promise((resolve, reject) => {
                let savedata = { ...item };
                savedata = remove_keys_from_object(savedata, state.exams.dont_save);

                api.patch({
                    name: 'patch_exam',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${state.periods.active}/exams/${item.id}/`,
                    data: savedata,
                    loading: true,
                })
                    .then((r) => {
                        Vue.toast({ msg: i18n.t('toasts.save_success') });
                        commit('updateItem', { type: 'exams', item });
                        resolve(r);
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        ExamsPost({ commit, state, rootState }, { item }) {
            return new Promise((resolve, reject) => {
                let savedata = { ...item };
                savedata = remove_keys_from_object(savedata, state.exams.dont_save);

                api.post({
                    name: 'post_exam',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${state.periods.active}/exams/`,
                    data: savedata,
                    loading: true,
                })
                    .then((r) => {
                        Vue.toast({ msg: i18n.t('toasts.save_success') });
                        if (r.data.id) {
                            item.id = r.data.id;
                        }
                        commit('addItem', { type: 'exams', item });
                        resolve(r);
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        ExamsDelete({ commit, state, rootState }, { ids }) {
            return new Promise((resolve, reject) => {
                api.delete({
                    name: 'delete_items',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${state.periods.active}/exams/${ids.join(',')}/`,
                    loading: true,
                })
                    .then(() => {
                        commit('removeItems', { type: 'exams', ids });
                        Vue.toast({ msg: i18n.t('toasts.delete_success') });
                        resolve();
                    })
                    .catch((err) => {
                        Vue.toast({ msg: i18n.t('toasts.delete_error'), classes: 'bg-danger' });
                        apiErrors.methods.apiError(err);
                        console.log(err);
                        reject();
                    });
            });
        },

        ExamsAvailability({ state, rootState }, { ids = [] }) {
            return new Promise((resolve, reject) => {
                api.get({
                    name: 'exam_availability',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${state.periods.active}/exams/${ids.join(',')}/availability`,
                    loading: true,
                })
                    .then((r) => {
                        resolve(r);
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        console.log(err);
                        reject();
                    });
            });
        },

        Schedule({ dispatch, state, rootState }, { cancel = false, year }) {
            return new Promise((resolve, reject) => {
                api.post({
                    name: 'autoplan_exams',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${state.periods.active}/${cancel === true ? 'de' : ''}schedule/`,
                    data: { year },
                })
                    .then((r) => {
                        dispatch('ExamsGet').then(() => {
                            resolve(r);
                        });
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        Restore({ dispatch, state, rootState }, { timestamp }) {
            return new Promise((resolve, reject) => {
                api.post({
                    name: 'autoplan_exams',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${state.periods.active}/restore/${timestamp}/`,
                })
                    .then((r) => {
                        dispatch('ExamsGet').then(() => {
                            dispatch('PeriodsGet', { id: state.periods.active })
                                .then(() => {
                                    resolve(r);
                                });
                        });
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        Publish({ commit, state, rootState }, { id, publish }) {
            if (!id) {
                id = state.periods.active;
            }
            return new Promise((resolve, reject) => {
                api.post({
                    name: 'publish_period',
                    endpoint: `schools/${rootState.settings.schoolid}/examperiods/${id}/${publish === true ? '' : 'un'}publish/`,
                    loading: true,
                })
                    .then(() => {
                        commit('publishPeriod', { id, publish });
                    })
                    .catch((err) => {
                        apiErrors.methods.apiError(err);
                        reject(err);
                    });
            });
        },

        Unplan({ dispatch, getters }, { ids = [] }) {
            let exam = getters.item('exams', ids[0]);
            if (exam) {
                exam = JSON.parse(JSON.stringify(exam));
                exam.planned.day = 0;
                exam.planned.from = 0;
                exam.planned.to = 0;
                exam.planned.combinations = [];
            }

            dispatch('ExamsPatch', {
                item: exam,
            });
        },
    },
};
