const dates = {
    methods: {
        create_date(timestamp, format) {
            if (typeof timestamp === 'object') {
                return timestamp;
            }
            if (format === 'ymd') {
                return new Date(timestamp);
            }

            switch (timestamp) {
            case 'now':
            case 'today':
                return new Date();

            case 'tomorrow':
                return new Date(Date.now() + (24 * 60 * 60 * 1000));

            default:
                if (!timestamp) {
                    return new Date();
                }
                return new Date(timestamp * 1000);
            }
        },

        create_date_from_time(time) {
            time = time.toString();
            const strlength = time.length;
            return new Date(0, 0, 0, time.substring(0, strlength - 2), time.substring(strlength - 2), 0);
        },

        output_templates(string) {
            switch (string) {
            case 'date':
                string = 'DDD d MMM';
                break;

            case 'date-short':
                string = 'd MM';
                break;

            case 'weekday':
                string = 'd DDD';
                break;

            default:
            }

            return this.output_templater(string);
        },

        output_templater(string) {
            const options = {};

            // Weekdays
            if (string.includes('DDD')) { // Sunday
                options.weekday = 'long';
            } else if (string.includes('DD')) { // Sun
                options.weekday = 'short';
            } else if (string.includes('D')) { // S
                options.weekday = 'narrow';
            }

            // Year
            if (string.includes('yyyy')) { // 2019
                options.year = 'numeric';
            } else if (string.includes('yy')) { // 19
                options.year = '2-digit';
            }

            // Month
            if (string.includes('mm')) { // 03
                options.month = '2-digit';
            } else if (string.includes('m')) { // 3
                options.month = 'numeric';
            } else if (string.includes('MMM')) { // March
                options.month = 'long';
            } else if (string.includes('MM')) { // Mar.
                options.month = 'short';
            }

            // Day
            if (string.includes('dd')) { // 07
                options.day = '2-digit';
            } else if (string.includes('d')) { // 7
                options.day = 'numeric';
            }

            // Hour
            if (string.includes('hh')) { // 07
                options.hour = '2-digit';
            } else if (string.includes('h')) { // 7
                options.hour = 'numeric';
            }

            // Minute
            if (string.includes('ii')) { // 07
                options.minute = '2-digit';
            }

            // Second
            if (string.includes('ss')) { // 07
                options.second = '2-digit';
            }

            return options;
        },

        timestamp_to(timestamp, output, midnight) {
            const date = this.create_date(timestamp);
            if (midnight) {
                date.setHours(0, 0, 0, 0);
            }
            if (output === 'timestamp') {
                return Math.floor(date / 1000);
            }
            if (output === 'Y-m-d') {
                return `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).substr(-2)}-${(`0${date.getDate()}`).substr(-2)}`;
            }
            if (output === 'time') {
                const time = `${date.getHours()}${(`0${date.getMinutes() + 1}`).substr(-2)}`;
                return parseInt(time, 10);
            }
            const output_template = typeof output === 'string' ? this.output_templates(output) : output;

            return date.toLocaleDateString(undefined, output_template);
        },

        time_to(number, output = 'readable') {
            let time = null;
            switch (output) {
            case 'readable': {
                time = number.toString();
                let len = time.length;
                if (len === 2) { time = `0${time}`; len = 3; } else if (len === 1) { time = `00${time}`; len = 3; }
                time = `${time.substring(0, len - 2)}:${time.substring(len - 2)}`;
                break;
            }

            case 'number':
                time = `${number.hours}${number.minutes < 10 ? `0${number.minutes}` : number.minutes}`;
                time = parseInt(time, 10);
                break;

            case 'minutes': {
                time = number.toString();
                let hours = 0;
                let minutes = 0;
                const len = time.length;
                if (len > 2) {
                    hours = time.substring(0, len - 2);
                    minutes = time.substring(len - 2);
                }
                hours *= 60;
                time = parseInt(hours, 10) + parseInt(minutes, 10);
                break;
            }

            default:
            }

            return time;
        },

        time_diff(one, two) { // (830, 1035) returns 205
            const startDate = this.create_date_from_time(one);
            const endDate = this.create_date_from_time(two);
            let diff = endDate.getTime() - startDate.getTime();
            const hours = Math.floor(diff / 1000 / 60 / 60);
            diff -= hours * 1000 * 60 * 60;
            const minutes = Math.floor(diff / 1000 / 60);
            return this.time_to({ hours, minutes }, 'number');
        },

        time_multiply(time, multiplier) { // (50, 2) returns 140
            const date = this.create_date_from_time(time);
            let new_minutes = date.getMinutes() * multiplier;
            let new_hours = 0;
            const spare_hours = new_minutes > 59 ? Math.floor(new_minutes / 60) : 0;
            new_hours = (date.getHours() * multiplier) + spare_hours;
            new_minutes %= 60;
            return this.time_to({ hours: new_hours, minutes: new_minutes }, 'number');
        },

        ymd_to(ymd, output) {
            const date = this.create_date(ymd, 'ymd');
            if (output === 'timestamp') {
                date.setHours(0, 0, 0, 0);
                return Math.floor(date / 1000);
            }
            const output_template = typeof output === 'string' ? this.output_templates(output) : output;

            return date.toLocaleDateString(undefined, output_template);
        },
    },
};

export default dates;
